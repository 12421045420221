import { combineReducers } from 'redux';
import { notificationReducer } from './notification';
import { userReducer } from './users';
import { authReducer } from './auth';
import { sportReducer } from './sports';
import { accountReducer } from './account';
import { genericReducer } from './generic';

const appReducer = combineReducers({
    notification: notificationReducer,
    user: userReducer,
    auth: authReducer,
    sport: sportReducer,
    account: accountReducer,
    generic: genericReducer,
});

const rootReducer = (state, action) => {
    const { type, payload } = action;
    let newState;

    if (type === 'APP_INIT') {
        newState = { ...state, internal: {} };
    } else if (type === 'RELOAD_STORE') {
        newState = { ...payload };
    } else if (type === 'CLEAR_INTERNAL_STORE') {
        newState = { routing: { ...state.routing } };
    }

    return appReducer(newState || state, action);
};

export default rootReducer;
