import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authActions, authSelectors } from '../../core/resource/auth';
import { AppWrapper } from '../layouts';
import { CustButton } from '../reusable';

const Register = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isSignupPending = useSelector(state =>
        authSelectors.getIsSignupPending(state),
    );
    const signupRes = useSelector(state => authSelectors.getSignupRes(state));
    const signupResError = useSelector(state =>
        authSelectors.getSignupError(state),
    );

    const [username, setUsername] = useState('admin');
    const [name, setName] = useState('name');
    const [email, setEmail] = useState('test@gmail.com');
    const [password, setPassword] = useState('one2eight');
    const [roleId, setRoleId] = useState(7);

    useEffect(() => {
        console.log({
            isSignupPending,
            signupRes,
            signupResError,
        });
    }, [isSignupPending, signupRes, signupResError]);

    const handleOnChange = e => {
        const { name, value } = e.target;
        console.log(name, value);
        switch (name) {
            case 'username':
                setUsername(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'role':
                setRoleId(value);
                break;
            default:
                console.log(name, value);
                break;
        }
    };

    const handleOnSignup = () => {
        dispatch(
            authActions.signupUserRequest({
                username,
                password,
                name,
                email,
                role_id: roleId,
            }),
        );
        console.log({
            username,
            password,
            name,
            email,
            roleId,
            isSignupPending,
            signupRes,
            signupResError,
        });
    };

    const handleGoToPage = () => {
        navigate('/login');
    };

    return (
        <AppWrapper>
            <main className="loginpg py-5 d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="login-form form-area">
                        <div className="logo-area text-center mb-4">
                            <img
                                src="assets/images/24x7exch-logo.png"
                                alt=""
                                className="img-fluid"
                            />
                            {/* <div className="logo-heading">24x7exch</div> */}
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h4 className="mb-0">Signup</h4>
                            </div>
                            <div className="card-body">
                                <form className="row g-3">
                                    <div className="col-md-6">
                                        <label
                                            htmlFor="inputEmail4"
                                            className="form-label">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputEmail4"
                                            name="name"
                                            value={name}
                                            onChange={handleOnChange}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label
                                            htmlFor="inputEmail4"
                                            className="form-label">
                                            Username
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputEmail4"
                                            name="username"
                                            value={username}
                                            onChange={handleOnChange}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <label
                                            htmlFor="inputEmail4"
                                            className="form-label">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                            name="email"
                                            value={email}
                                            onChange={handleOnChange}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <label
                                            htmlFor="inputPassword4"
                                            className="form-label">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="inputPassword4"
                                            name="password"
                                            value={password}
                                            onChange={handleOnChange}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <CustButton
                                            onClick={handleOnSignup}
                                            className="w-100"
                                            text={
                                                isSignupPending
                                                    ? 'Please wait...'
                                                    : 'Signup'
                                            }
                                        />
                                    </div>
                                    <div className="seperator-line py-3 text-center">
                                        <span className="or">OR</span>
                                    </div>
                                    <div className="register-wrap">
                                        <CustButton
                                            onClick={handleGoToPage}
                                            className="w-100"
                                            text={'Login'}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </AppWrapper>
    );
};

export default Register;
