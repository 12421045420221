import { createAction } from 'redux-act';

const handleClearGenericStore = createAction(
    'HANDLE_CLEAR_GENERIC_STORE',
    data => data,
);

const getAnnouncementsRequest = createAction(
    'GET_ANNOUNCEMENTS_REQUEST',
    data => data,
);
const getAnnouncementsSuccess = createAction(
    'GET_ANNOUNCEMENTS_SUCCESS',
    data => data,
);
const getAnnouncementsError = createAction(
    'GET_ANNOUNCEMENTS_ERROR',
    data => data,
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    handleClearGenericStore,

    getAnnouncementsRequest,
    getAnnouncementsSuccess,
    getAnnouncementsError,
};
