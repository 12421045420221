import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { CustButton } from '../reusable';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const ChipsDepositModal = props => {
    const {
        isShow,
        toggleModal,
        handleOnSubmit,
        chipType,
        selectedUserData,
        backdrop,
        parentChipsBalance,
    } = props;
    const { isGetUserBalancePending, getUserBalanceRes } = useSelector(
        state => state.account,
    );
    const { isChipsDepositPending, isChipsWithdrawPending } = useSelector(
        state => state.account,
    );

    const [currentPassword, setCurrentPassword] = useState('');
    const [chips, setChips] = useState('');
    const [parentChips, setParentChips] = useState(parentChipsBalance);
    const [userBalance, setUserBalance] = useState(0);
    const [parentNewChips, setParentNewChips] = useState(0);
    const [userNewChips, setUserNewChips] = useState(0);

    useEffect(() => {
        setChips('');
        setCurrentPassword('');
        setUserBalance(0);
        setParentNewChips(0);
        setUserNewChips(0);
    }, [isShow]);

    useEffect(() => {
        setParentChips(parentChipsBalance);
    }, [parentChipsBalance]);

    const handleOnChange = e => {
        const { name, value } = e.target;
        switch (name) {
            case 'currentPassword':
                setCurrentPassword(value);
                break;
            case 'chips':
                let amount = parseFloat(value, 10);
                amount = amount > 0 ? amount : 0;
                setChips(value);
                setParentNewChips(parentChips - amount);
                setUserNewChips(userBalance + amount);

                break;
            default:
                console.log(name, value);
                break;
        }
    };

    const handleOnSave = () => {
        handleOnSubmit({
            id: selectedUserData.id,
            chip: chips,
            password: currentPassword,
        });
    };

    const getOtherInfo = infoType => {
        if (infoType === 'title') {
            return `Chips In/Out of ${selectedUserData?.username || ''}`;
        }
        if (infoType === 'btnTxt') {
            return chipType === 'd' ? 'Deposit' : 'Withdrawal';
        }
        return '';
    };
    return (
        <Modal
            show={isShow}
            onHide={toggleModal}
            backdrop={backdrop || true}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>{getOtherInfo('title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="row g-3">
                    <div className="col-md-12">
                        <label htmlFor="inputEmail4" className="form-label">
                            Chips
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            name="chips"
                            value={chips}
                            onChange={handleOnChange}
                        />
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="inputEmail4" className="form-label">
                            Current Password
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            id="inputEmail4"
                            name="currentPassword"
                            value={currentPassword}
                            onChange={handleOnChange}
                        />
                    </div>
                    <div className="Col-md-12">
                        <Table className="table table-bordered table-hover">
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Parent Chips</strong>
                                    </td>
                                    <td>{parentChips}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>User Balance</strong>
                                    </td>
                                    <td>{userBalance}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Parent New Chips</strong>
                                    </td>
                                    <td>{parentNewChips}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>User New Chips</strong>
                                    </td>
                                    <td>{userNewChips}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer className="two-btns-footer">
                <CustButton
                    onClick={toggleModal}
                    text={'Cancel'}
                    variant="danger"
                    className="cancel-btn"
                />
                <CustButton
                    onClick={handleOnSave}
                    isLoader={
                        isChipsDepositPending || isChipsWithdrawPending || false
                    }
                    text={getOtherInfo('btnTxt')}
                    className="save-btn"
                />
            </Modal.Footer>
        </Modal>
    );
};

export default ChipsDepositModal;
