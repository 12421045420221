import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authActions, authSelectors } from '../../core/resource/auth';
import { AppWrapper } from '../layouts';
import { CustButton, CustInputErrorLabel } from '../reusable';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoginPending = useSelector(state =>
        authSelectors.getIsLoginPending(state),
    );
    const loginRes = useSelector(state => authSelectors.getLoginRes(state));
    const loginResError = useSelector(state =>
        authSelectors.getLoginError(state),
    );

    const [isFormTouched, setIsFormTouched] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    const [errors, setErrors] = useState({
        username: 'Username is required',
        password: 'Password is required',
    });

    useEffect(() => {
        if (!isLoginPending) {
            if (loginResError) {
                return;
            }
            if (loginRes?.data?.data && loginRes?.data?.success) {
                const userData = loginRes?.data?.data?.user || null;
                console.log({ userData });
                if (userData?.role_id === 6) {
                    navigate('/');
                } else {
                    navigate('/users');
                }
            }
        }
    }, [isLoginPending, loginRes, loginResError]);

    const handleOnChange = e => {
        const { name, value } = e.target;
        console.log(name, value);
        setFormData({
            ...formData,
            [name]: value,
        });

        const validationErrors = validateInput(name, value);
        setIsFormTouched(true);
        setErrors({
            ...errors,
            [name]: validationErrors,
        });
    };

    const validateInput = (name, value) => {
        let errorMsg = '';
        switch (name) {
            case 'username':
                if (value?.trim()?.length === 0) {
                    errorMsg = 'Username is reuired';
                }
                return errorMsg;
            case 'password':
                if (value?.trim()?.length === 0) {
                    errorMsg = 'Password is reuired';
                }
                return errorMsg;
            default:
                console.log('');
                break;
        }
    };

    const isFormValid = () => {
        // Check if there are any errors in the errors object
        return Object.values(errors).every(error => error === '');
    };

    const handleOnLogin = () => {
        setIsFormTouched(true);
        if (isFormValid()) {
            dispatch(
                authActions.loginUserRequest({
                    username: formData.username,
                    password: formData.password,
                }),
            );
        } else {
        }
    };

    const handleGoToPage = () => {
        navigate('/signup');
    };

    return (
        <AppWrapper>
            <main className="loginpg py-5 d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="login-form form-area">
                        <div className="logo-area text-center mb-4">
                            <img
                                src="assets/images/24x7exch-logo.png"
                                alt=""
                                className="img-fluid"
                            />
                            {/* <div className="logo-heading">24x7exch</div> */}
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h4 className="mb-0">Login</h4>
                            </div>
                            <div className="card-body">
                                <form className="row g-3">
                                    <div className="col-md-12">
                                        <label
                                            htmlFor="inputEmail4"
                                            className="form-label">
                                            Username
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inputEmail4"
                                            name="username"
                                            value={formData.username}
                                            onChange={handleOnChange}
                                        />
                                        <CustInputErrorLabel
                                            isTouched={isFormTouched}
                                            errorMsg={errors?.username || ''}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <label
                                            htmlFor="inputPassword4"
                                            className="form-label">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="inputPassword4"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleOnChange}
                                        />
                                        <CustInputErrorLabel
                                            isTouched={isFormTouched}
                                            errorMsg={errors?.password || ''}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <CustButton
                                            onClick={handleOnLogin}
                                            className="w-100"
                                            isLoader={isLoginPending}
                                            text={'Login'}
                                        />
                                    </div>
                                    <div className="col-12">
                                        {/* <div className="forgot-pass">
                                            <a href="">Forget Password ?</a>
                                        </div> */}

                                        {/* <div className="seperator-line py-4 text-center">
                                            <span className="or">OR</span>
                                        </div>
                                        <div className="register-wrap">
                                            <CustButton
                                                onClick={handleGoToPage}
                                                className="w-100"
                                                text={'Register'}
                                            />
                                        </div> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </AppWrapper>
    );
};

export default Login;
