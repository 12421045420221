import React from 'react';

const CustInputErrorLabel = props => {
    const { isTouched, errorMsg } = props;
    if (isTouched && errorMsg) {
        return <span className="field-warning">{errorMsg}</span>;
    }
    return null;
};

export default CustInputErrorLabel;
