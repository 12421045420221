import React from 'react';
import { AppWrapper } from '../layouts';

function MatchLive() {
    return (
        <AppWrapper>
            <div className="container-fluid py-5">
                <div className="row">
                    <div className="col-12 col-md-7">
                        <div className="match-monitoring">
                            <div className="card live-match-result-table mb-4">
                                <div className="card-header match-play-header">
                                    <div className="match-tital">
                                        <div id="matchnameid">
                                            India v Pakistan
                                        </div>
                                    </div>
                                    <div className="match-live-result-btns">
                                        <div className="btn-group">
                                            <button
                                                href="#"
                                                className="btn btn-primary">
                                                Monitoring
                                            </button>
                                            <button
                                                href="#"
                                                className="btn btn-primary">
                                                Suruvilance
                                            </button>
                                            <button
                                                href="#"
                                                className="btn btn-primary">
                                                User Book
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <div className="live-match-table">
                                        <div className="match-result-head">
                                            <div>
                                                <div className="team-play">
                                                    INDIA -{' '}
                                                    <span className="team-score">
                                                        122/0 (22.4)
                                                    </span>
                                                </div>
                                                <div className="team-play">
                                                    PAKISTAN -{' '}
                                                    <span className="team-score">
                                                        225/9 (50)
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="match-result-body">
                                            <div className="match-result-body-inner">
                                                <div className="row">
                                                    <div className="col-12 col-md-9 col-lg-9">
                                                        <div className="player">
                                                            <div className="playername">
                                                                <span className="sport-icon">
                                                                    <img
                                                                        src="assets/images/icon/svg/bat-icons.svg"
                                                                        className=""
                                                                    />
                                                                </span>{' '}
                                                                Virat Kohli{' '}
                                                                <span
                                                                    className="batscore"
                                                                    id="bat1score">
                                                                    <strong>
                                                                        50 (32)
                                                                    </strong>
                                                                </span>
                                                            </div>
                                                            <div className="playername">
                                                                <span className="sport-icon">
                                                                    <img
                                                                        src="assets/images/icon/svg/bat-icons.svg"
                                                                        className=""
                                                                    />
                                                                </span>{' '}
                                                                Rohit Sharma{' '}
                                                                <span
                                                                    className="batscore"
                                                                    id="bat1score">
                                                                    <strong>
                                                                        70 (58)
                                                                    </strong>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3 col-lg-3">
                                                        <div id="live-run">
                                                            6
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="match-over">
                                                <ul>
                                                    <li>
                                                        <span className="sport-icon">
                                                            <img
                                                                src="assets/images/icon/svg/ball-icon.svg"
                                                                className=""
                                                            />
                                                        </span>
                                                    </li>
                                                    <li className="overball runball">
                                                        0
                                                    </li>
                                                    <li className="overball runball">
                                                        6
                                                    </li>
                                                    <li className="overball runball">
                                                        2
                                                    </li>
                                                    <li className="overball">
                                                        -
                                                    </li>
                                                    <li className="overball runball">
                                                        1
                                                    </li>
                                                    <li className="overball">
                                                        -
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="match-result-footer">
                                            India elected to field
                                        </div>
                                    </div>
                                    <div className="d-none">
                                        <div className="match-info-card">
                                            <iframe
                                                id="animscore"
                                                src="https://score.newbsf.com/#/score1/32742110"
                                                className="iframestyle"
                                                title="Match Score"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="match-table-area">
                                <div className="match-odds-sec">
                                    <div className="match-status">
                                        MATCHED -{' '}
                                        <span className="count">0</span>
                                    </div>
                                    <div className="match-schedule">
                                        <div className="">
                                            <ul>
                                                <li>
                                                    <button className="">
                                                        <box-icon
                                                            name="volume-full"
                                                            color="red"></box-icon>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="">
                                                        <box-icon
                                                            name="tv"
                                                            color="green"></box-icon>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="tvformobilediv">
                                    <iframe
                                        width="420"
                                        height="345"
                                        src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"></iframe>
                                </div>

                                <div className="">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Min : 100 Max : 10000</th>
                                                <th></th>
                                                <th className="text-center">
                                                    Back
                                                </th>
                                                <th className="text-center">
                                                    Lay
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>India</td>
                                                <td></td>
                                                <td>
                                                    <div className="text-center">
                                                        2.06
                                                        <small className="d-block">
                                                            728.13
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        2.26
                                                        <small className="d-block">
                                                            411.12
                                                        </small>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Pakistan</td>
                                                <td></td>
                                                <td>
                                                    <div className="text-center">
                                                        2.06
                                                        <small className="d-block">
                                                            728.13
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        2.26
                                                        <small className="d-block">
                                                            411.12
                                                        </small>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Min : 100 Max : 10000</th>
                                                <th></th>
                                                <th className="text-center">
                                                    Back
                                                </th>
                                                <th className="text-center">
                                                    Lay
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>India</td>
                                                <td></td>
                                                <td>
                                                    <div className="text-center">
                                                        2.06
                                                        <small className="d-block">
                                                            728.13
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        2.26
                                                        <small className="d-block">
                                                            411.12
                                                        </small>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Pakistan</td>
                                                <td></td>
                                                <td>
                                                    <div className="text-center">
                                                        2.06
                                                        <small className="d-block">
                                                            728.13
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        2.26
                                                        <small className="d-block">
                                                            411.12
                                                        </small>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>POS. NO</th>
                                                <th className="text-center">
                                                    NO [ L ]
                                                </th>
                                                <th className="text-center">
                                                    YES [ B ]
                                                </th>
                                                <th>POS. YES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="match-hotinfo">
                                                        <div className="match-hotinfo-inner">
                                                            <div className="fncyminmaxcss">
                                                                <span className="fancymin36">
                                                                    Min:100.00
                                                                </span>
                                                                |
                                                                <span className="fancymax36">
                                                                    Max:25K
                                                                </span>
                                                            </div>
                                                            <div className="match-hithead">
                                                                1st 3 wkt runs
                                                                IND(IND vs
                                                                SA)adv
                                                            </div>
                                                        </div>
                                                        <button className="btn book-btn btn-sm">
                                                            BOOK
                                                        </button>
                                                    </div>
                                                </td>
                                                <td>-</td>
                                                <td>
                                                    <div className="text-center red-text">
                                                        2.06
                                                        <small className="d-block">
                                                            728.13
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center green-text">
                                                        2.26
                                                        <small className="d-block">
                                                            411.12
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>0.00</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="match-hotinfo">
                                                        <div className="match-hotinfo-inner">
                                                            <div className="fncyminmaxcss">
                                                                <span className="fancymin36">
                                                                    Min:100.00
                                                                </span>
                                                                |
                                                                <span className="fancymax36">
                                                                    Max:25K
                                                                </span>
                                                            </div>
                                                            <div className="match-hithead">
                                                                1st 3 wkt runs
                                                                IND(IND vs
                                                                SA)adv
                                                            </div>
                                                        </div>
                                                        <button className="btn book-btn btn-sm">
                                                            BOOK
                                                        </button>
                                                    </div>
                                                </td>
                                                <td>-</td>
                                                <td>
                                                    <div className="text-center red-text">
                                                        2.06
                                                        <small className="d-block">
                                                            728.13
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center green-text">
                                                        2.26
                                                        <small className="d-block">
                                                            411.12
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>0.00</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="match-hotinfo">
                                                        <div className="match-hotinfo-inner">
                                                            <div className="fncyminmaxcss">
                                                                <span className="fancymin36">
                                                                    Min:100.00
                                                                </span>
                                                                |
                                                                <span className="fancymax36">
                                                                    Max:25K
                                                                </span>
                                                            </div>
                                                            <div className="match-hithead">
                                                                1st 3 wkt runs
                                                                IND(IND vs
                                                                SA)adv
                                                            </div>
                                                        </div>
                                                        <button className="btn book-btn btn-sm">
                                                            BOOK
                                                        </button>
                                                    </div>
                                                </td>
                                                <td>-</td>
                                                <td>
                                                    <div className="text-center red-text">
                                                        2.06
                                                        <small className="d-block">
                                                            728.13
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center green-text">
                                                        2.26
                                                        <small className="d-block">
                                                            411.12
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>0.00</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="match-hotinfo">
                                                        <div className="match-hotinfo-inner">
                                                            <div className="fncyminmaxcss">
                                                                <span className="fancymin36">
                                                                    Min:100.00
                                                                </span>
                                                                |
                                                                <span className="fancymax36">
                                                                    Max:25K
                                                                </span>
                                                            </div>
                                                            <div className="match-hithead">
                                                                1st 3 wkt runs
                                                                IND(IND vs
                                                                SA)adv
                                                            </div>
                                                        </div>
                                                        <button className="btn book-btn btn-sm">
                                                            BOOK
                                                        </button>
                                                    </div>
                                                </td>
                                                <td>-</td>
                                                <td>
                                                    <div className="text-center red-text">
                                                        2.06
                                                        <small className="d-block">
                                                            728.13
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center green-text">
                                                        2.26
                                                        <small className="d-block">
                                                            411.12
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>0.00</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="match-hotinfo">
                                                        <div className="match-hotinfo-inner">
                                                            <div className="fncyminmaxcss">
                                                                <span className="fancymin36">
                                                                    Min:100.00
                                                                </span>
                                                                |
                                                                <span className="fancymax36">
                                                                    Max:25K
                                                                </span>
                                                            </div>
                                                            <div className="match-hithead">
                                                                1st 3 wkt runs
                                                                IND(IND vs
                                                                SA)adv
                                                            </div>
                                                        </div>
                                                        <button className="btn book-btn btn-sm">
                                                            BOOK
                                                        </button>
                                                    </div>
                                                </td>
                                                <td>-</td>
                                                <td>
                                                    <div className="text-center red-text">
                                                        2.06
                                                        <small className="d-block">
                                                            728.13
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center green-text">
                                                        2.26
                                                        <small className="d-block">
                                                            411.12
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>0.00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-5">
                        <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample">
                            <div className="accordion-item">
                                <ul
                                    className="nav nav-tabs"
                                    id="myTab"
                                    role="tablist">
                                    <li
                                        className="nav-item"
                                        role="presentation">
                                        <button
                                            className="nav-link active"
                                            id="home-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#home"
                                            type="button"
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected="true">
                                            All Bet 0
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item"
                                        role="presentation">
                                        <button
                                            className="nav-link"
                                            id="profile-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="profile"
                                            aria-selected="false">
                                            Fancy Bet 0
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item"
                                        role="presentation">
                                        <button
                                            className="nav-link"
                                            id="contact-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#contact"
                                            type="button"
                                            role="tab"
                                            aria-controls="contact"
                                            aria-selected="false">
                                            Current Position
                                        </button>
                                    </li>
                                </ul>
                                <h2
                                    className="accordion-header"
                                    id="flush-headingOne">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOne">
                                        Accordion Item #1
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseOne"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <div
                                            className="tab-content"
                                            id="myTabContent">
                                            <div
                                                className="tab-pane fade show active"
                                                id="home"
                                                role="tabpanel"
                                                aria-labelledby="home-tab">
                                                Placeholder content for this
                                                accordion, which is intended to
                                                demonstrate the class. This is
                                                the first items accordion body.
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="profile"
                                                role="tabpanel"
                                                aria-labelledby="profile-tab">
                                                lorem
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="contact"
                                                role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                ...
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppWrapper>
    );
}

export default MatchLive;
