import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { authActions, authSelectors } from '../../core/resource/auth';
import { CustButton, CustInputErrorLabel } from '../reusable';
import { userActions } from '../../core/resource/users';

const AddUserModal = props => {
    const {
        isShow,
        toggleAddUserModal,
        handleOnAddUser,
        backdrop,
        roleId,
        parentId,
    } = props;
    const { isAddUserPending } = useSelector(state => state.user);

    const [isFormTouched, setIsFormTouched] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        name: '',
        email: '',
        password: '',
        role_id: roleId,
        parent_id: parentId,
    });
    const [errors, setErrors] = useState({
        username: 'Username is required',
        name: 'Name is required',
        email: '',
        password: 'Password is required',
    });

    useEffect(() => {}, [formData]);

    const handleOnChange = e => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        console.log(name, value);

        const validationErrors = validateInput(name, value);
        setIsFormTouched(true);
        setErrors({
            ...errors,
            [name]: validationErrors,
        });
    };

    const validateInput = (name, value) => {
        let errorMsg = '';
        switch (name) {
            case 'username':
                if (value?.trim()?.length === 0) {
                    errorMsg = 'Username is reuired';
                }
                return errorMsg;
            case 'name':
                console.log({ value }, value?.trim()?.length);
                if (value?.trim()?.length === 0) {
                    errorMsg = 'Name is reuired';
                }
                return errorMsg;
            case 'email':
                if (value?.trim()?.length === 0) {
                    errorMsg = 'email is reuired';
                }
                return errorMsg;
            case 'password':
                if (value?.trim()?.length === 0) {
                    errorMsg = 'Password is reuired';
                }
                return errorMsg;
            default:
                console.log('');
                break;
        }
    };

    const isFormValid = () => {
        // Check if there are any errors in the errors object
        return Object.values(errors).every(error => error === '');
    };

    const handleOnSubmit = () => {
        setIsFormTouched(true);
        if (isFormValid()) {
            formData.role_id = roleId;
            formData.parent_id = parentId;
            formData.email = `${formData.username}@gmail.com`;
            handleOnAddUser(formData);
        } else {
        }
    };

    return (
        <Modal
            show={isShow}
            onHide={toggleAddUserModal}
            backdrop={backdrop || true}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Add User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="row g-3">
                    <div className="col-md-12">
                        <label htmlFor="inputEmail4" className="form-label">
                            Name
                        </label>
                        <input
                            type="text"
                            className={`form-control ${
                                isFormTouched && errors?.name ? 'required' : ''
                            }`}
                            id="inputEmail4"
                            name="name"
                            value={formData.name}
                            onChange={handleOnChange}
                        />
                        <CustInputErrorLabel
                            isTouched={isFormTouched}
                            errorMsg={errors?.name || ''}
                        />
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="inputEmail4" className="form-label">
                            Username
                        </label>
                        <input
                            type="text"
                            className={`form-control ${
                                isFormTouched && errors?.username
                                    ? 'required'
                                    : ''
                            }`}
                            id="inputEmail4"
                            name="username"
                            value={formData.username}
                            onChange={handleOnChange}
                        />
                        <CustInputErrorLabel
                            isTouched={isFormTouched}
                            errorMsg={errors?.username || ''}
                        />
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="inputPassword4" className="form-label">
                            Password
                        </label>
                        <input
                            type="password"
                            className={`form-control ${
                                isFormTouched && errors?.password
                                    ? 'required'
                                    : ''
                            }`}
                            id="inputPassword4"
                            name="password"
                            value={formData.password}
                            onChange={handleOnChange}
                        />

                        <CustInputErrorLabel
                            isTouched={isFormTouched}
                            errorMsg={errors?.password || ''}
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer className="two-btns-footer">
                <CustButton
                    onClick={toggleAddUserModal}
                    text={'Cancel'}
                    variant="danger"
                    className="cancel-btn"
                />
                <CustButton
                    onClick={handleOnSubmit}
                    isLoader={isAddUserPending}
                    text={'Add'}
                    className="save-btn"
                />
            </Modal.Footer>
        </Modal>
    );
};

export default AddUserModal;
