import { createReducer } from 'redux-act';
import actions from './notificationActions';

const initialState = {
    isOpen: false,
    isDialogOpen: false,
    messageText: '',
    dialogText: '',
    dialogBtnText: '',
    duration: 0, // in millis
    isOpenSnackbar: false,
    alertType: 'success',
};

const handleOpen = (state, action, position = undefined) => ({
    ...state,
    messageText: action,
    isOpen: true,
    duration: 0,
    position,
});

const handleOpenWithDuration = (state, payload) => ({
    ...state,
    messageText: payload.text,
    isOpen: true,
    duration: parseInt(payload.duration, 10),
    position: payload.position ? payload.position : undefined,
});

const handleClose = state => ({
    ...state,
    isOpen: false,
    messageText: '',
    duration: 0,
});

const handleOpenDialog = (state, payload) => ({
    ...state,
    isDialogOpen: true,
    dialogText: payload.dialogText,
    dialogBtnText: payload.dialogBtnText,
    isForceUpdate: payload.isForceUpdate || false,
});

const handleCloseDialog = state => ({
    ...state,
    isDialogOpen: false,
    isForceUpdate: false,
    dialogText: '',
    dialogBtnText: '',
});

const handleOpenSnackbar = (state, payload) => ({
    ...state,
    messageText: payload.text,
    alertType: payload.alertType,
    isOpenSnackbar: true,
    duration: parseInt(payload.duration, 10),
    position: payload.position ? payload.position : undefined,
});

const handleCloseSnackbar = state => ({
    ...state,
    isOpenSnackbar: false,
    messageText: '',
    alertType: '',
    duration: 0,
});

export default createReducer(
    {
        [actions.openMessageModal]: handleOpen,
        [actions.openMessageModalDuration]: handleOpenWithDuration,
        [actions.closeMessageModal]: handleClose,
        [actions.openDialog]: handleOpenDialog,
        [actions.closeDialog]: handleCloseDialog,
        [actions.openSnackbar]: handleOpenSnackbar,
        [actions.closeSnackbar]: handleCloseSnackbar,
    },
    initialState,
);
