import moment from 'moment-timezone';

const userStorageKey = 'user_info';
const defaultTimezone = 'Asia/Kolkata';

const setDataOnLocal = (data, key) => {
    const storageKey = key || userStorageKey;
    sessionStorage.setItem(storageKey, JSON.stringify(data));
};
const getDataOnLocal = key => {
    const storageKey = key || userStorageKey;
    const data = sessionStorage.getItem(storageKey);
    return data ? JSON.parse(data) : null;
};
const removeDataOnLocal = key => {
    const storageKey = key || userStorageKey;
    return sessionStorage.removeItem(storageKey);
};

// local storage
const setDataOnLocalstorage = (data, key) => {
    const storageKey = key || userStorageKey;
    localStorage.setItem(storageKey, JSON.stringify(data));
};
const getDataFromLocalstorage = key => {
    const storageKey = key || userStorageKey;
    const data = localStorage.getItem(storageKey);
    return data ? JSON.parse(data) : null;
};
const removeDataFromLocalstorage = key => {
    const storageKey = key || userStorageKey;
    return localStorage.removeItem(storageKey);
};

const convertToFile = (file, convertTo = 'base64') => {
    return new Promise((resolve, reject) => {
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = error => {
            reject(error);
        };
    });
};
const convertValue = (val, convertTo = 'float') => {
    if (val) {
        if (convertTo === 'float') return parseFloat(val, 10).toFixed(2);
    }
    return val;
};
const convertToDate = (dateTimestamp, format = 'DD/MM/YYYY') => {
    return moment.tz(dateTimestamp, defaultTimezone).format(format);
};
const convertToTS = (dateTimestamp, format = 'DD/MM/YYYY') => {
    console.log({ dateTimestamp });
    return moment(dateTimestamp).format(format);
};

const getTableSNum = (perPageRec, page, index) => {
    return perPageRec * (page - 1) + (index + 1);
};

const getCurrentUserRole = (loggedUserData, roles) => {
    if (roles?.length) {
        const currentRole = roles.filter(
            item => item.id === loggedUserData.role_id,
        );
        if (currentRole?.length) {
            return currentRole[0];
        }
    }
    return null;
};
const getRoleInfoById = (roleId, roles) => {
    if (roles?.length) {
        const roleData = roles.filter(item => item.id === roleId);
        if (roleData?.length) {
            return roleData[0];
        }
    }
    return null;
};

export {
    setDataOnLocal,
    getDataOnLocal,
    removeDataOnLocal,
    convertToFile,
    convertValue,
    convertToDate,
    convertToTS,
    setDataOnLocalstorage,
    getDataFromLocalstorage,
    removeDataFromLocalstorage,
    getTableSNum,
    getCurrentUserRole,
    getRoleInfoById,
};
