import endpoints from '../../configs/Endpoints.config';
import { getAxiosConfig, getFomatedError } from '../../services/ApiService';
import { defaultPerPageRecords } from '../../configs/Constants.config';

const addUser = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${endpoints.baseURL.apiUrl}${endpoints.user.add}`,
        method: 'POST',
        data: params,
        headers: {},
        mode: 'no-cors',
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });
    return response;
};

const changePassword = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${endpoints.baseURL.apiUrl}${endpoints.user.chnagePassword}`,
        method: 'POST',
        data: params,
        headers: {},
        mode: 'no-cors',
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });
    return response;
};

const deleteUser = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${endpoints.baseURL.authService}${endpoints.user.delete}`,
        method: 'DELETE',
        headers: {},
        data: params,
        mode: 'no-cors',
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });

    return response;
};

const getUsers = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    let uri = `${endpoints.baseURL.apiUrl}${endpoints.user.list.replace(
        ':USER_ROLE',
        params.role,
    )}`;
    if (params?.username) {
        uri = `${
            endpoints.baseURL.apiUrl
        }${endpoints.user.getChildUsers.replace(':USERNAME', params.username)}`;
    }
    const response = axiosInstance({
        url: uri,
        method: 'GET',
        headers: {},
        params: {
            page: params?.page || 1,
            show: params?.show || defaultPerPageRecords,
        },
        mode: 'no-cors',
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });

    return response;
};

const getRoles = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${endpoints.baseURL.apiUrl}${endpoints.user.getRoles}`,
        method: 'GET',
        headers: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });

    return response;
};

const getProfile = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${endpoints.baseURL.apiUrl}${endpoints.user.getProfile}`,
        method: 'GET',
        headers: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });

    return response;
};

const getUserDetails = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${
            endpoints.baseURL.apiUrl
        }${endpoints.user.getUserDetails.replace(
            ':USER_SLUG',
            params.user_slug,
        )}`,
        method: 'GET',
        headers: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });

    return response;
};

const getUserChilds = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${endpoints.baseURL.apiUrl}${endpoints.user.getUserChilds.replace(
            ':USER_ROLE',
            params.user_slug,
        )}`,
        method: 'GET',
        headers: {},
        params: {
            page: params?.page || 1,
            show: params?.show || 10,
        },
        mode: 'no-cors',
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });

    return response;
};

const getUserParents = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${
            endpoints.baseURL.apiUrl
        }${endpoints.user.getUserParents.replace(
            ':USER_ROLE',
            params.user_slug,
        )}`,
        method: 'GET',
        headers: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });

    return response;
};

const updateUserStatus = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${endpoints.baseURL.apiUrl}${endpoints.user.updateUserStatus}`,
        method: 'POST',
        data: params,
        headers: {},
        mode: 'no-cors',
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });
    return response;
};

export default {
    addUser,
    changePassword,
    deleteUser,
    getUsers,
    getRoles,
    getProfile,
    getUserDetails,
    getUserChilds,
    getUserParents,
    updateUserStatus,
};
