const version = '0.0.1';
const defaultPerPageRecords = 10;
const alertOptions = {
    defaultAlertDuration: 3000, // miliseconds
    defaultAlertPosition: 'top-end',
};

const fullMonths = [
    { name: 'January - December', number: '1/12' },
    { name: 'February - January', number: '2/1' },
    { name: 'March - February', number: '3/2' },
    { name: 'April - March', number: '4/3' },
    { name: 'May - April', number: '5/4' },
    { name: 'June - May', number: '6/5' },
    { name: 'July - June', number: '7/6' },
    { name: 'August - July', number: '8/7' },
    { name: 'September - August', number: '9/8' },
    { name: 'October - September', number: '10/9' },
    { name: 'November - October', number: '11/10' },
    { name: 'December - November', number: '12/1' },
];

export { version, defaultPerPageRecords, alertOptions, fullMonths };
