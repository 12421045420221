import { createAction } from 'redux-act';

const handleClearSportStore = createAction(
    'HANDLE_CLEAR_SPORT_STORE',
    data => data,
);

const getAllSportsRequest = createAction(
    'GET_ALL_SPORTS_REQUEST',
    data => data,
);
const getAllSportsSuccess = createAction(
    'GET_ALL_SPORTS_SUCCESS',
    data => data,
);
const getAllSportsError = createAction('GET_ALL_SPORTS_ERROR', data => data);

const getUserSportsRequest = createAction(
    'GET_USER_SPORTS_REQUEST',
    data => data,
);
const getUserSportsSuccess = createAction(
    'GET_USER_SPORTS_SUCCESS',
    data => data,
);
const getUserSportsError = createAction('GET_USER_SPORTS_ERROR');

const addUpdateSportRequest = createAction(
    'ADD_UPDATE_SPORT_REQUEST',
    data => data,
);
const addUpdateSportSuccess = createAction(
    'ADD_UPDATE_SPORT_SUCCESS',
    data => data,
);
const addUpdateSportError = createAction('ADD_UPDATE_SPORT_ERROR');

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    handleClearSportStore,

    getAllSportsRequest,
    getAllSportsSuccess,
    getAllSportsError,

    getUserSportsRequest,
    getUserSportsSuccess,
    getUserSportsError,

    addUpdateSportRequest,
    addUpdateSportSuccess,
    addUpdateSportError,
};
