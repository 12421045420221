import { createAction } from 'redux-act';

const openMessageModal = createAction('OPEN_MESSAGE_MODAL', data => data);
const openMessageModalDuration = createAction(
    'OPEN_MESSAGE_MODAL_DURATION',
    data => data,
);
const closeMessageModal = createAction('CLOSE_MESSAGE_NOTIFICATION');
const openDialog = createAction('OPEN_DIALOG');
const closeDialog = createAction('CLOSE_DIALOG');
const openSnackbar = createAction('OPEN_SNACKBAR', data => data);
const closeSnackbar = createAction('CLOSE_SNACKBAR');

export default {
    openMessageModal,
    openMessageModalDuration,
    closeMessageModal,
    openDialog,
    closeDialog,
    openSnackbar,
    closeSnackbar,
};
