import endpoints from '../../configs/Endpoints.config';
import { getAxiosConfig, getFomatedError } from '../../services/ApiService';

const getAnnouncements = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${endpoints.baseURL.apiUrl}${endpoints.generic.getAnnouncement}`,
        method: 'GET',
        headers: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });
    return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAnnouncements,
};
