const endpoints = {
    baseURL: {
        mainUrl: 'https://bat-laravel.dev',
        // apiUrl: 'https://bat-laravel.dev/api/v1',
        apiUrl: 'https://24x7exch-api.codexeco.com/api/v1',
    },
    auth: {
        login: '/login',
        signup: '/register',
    },
    generic: {
        getAnnouncement: '/get-announcement',
    },
    account: {
        getUserBalance: '/user-balance/:USER_SLUG',
        chipWithdraw: '/withdrawal',
        chipDeposit: '/deposit',
    },
    user: {
        create: '/create-user',
        add: '/create-user',
        logout: '/logout',
        list: '/list-user/:USER_ROLE',
        getChildUsers: '/list-child-user/:USERNAME',
        getUserChilds: '/get-childs/:USER_SLUG',
        getUserParents: '/get-parents/:USER_SLUG',
        getRoles: '/get-roles',
        getProfile: '/profile',
        getUserDetails: '/user-details/:USER_SLUG',
        resetPassword: '/reset-password',
        chnagePassword: '/change-password',
        updateUserStatus: '/user-status',
        toggleBetlock: '/toggle-betlock/:USER_SLUG',
    },
    sport: {
        list: '/sports',
        addUpdate: '/user-sports',
        getUserSports: '/user-sports/:USER_SLUG',
    },
};

export default endpoints;
