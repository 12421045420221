import React from 'react';

function Dashboard() {
    return (
        <div className="container">
            <div className="content-area mb-4">
                <div className="card-table">
                    <div className="card-header">
                        <div className="header-w-icon">
                            <span>
                                <img src="assets/images/icon/game.png" alt="" />
                            </span>
                            <h4 className="mb-0">Cricket</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="game-table table-responsive">
                            <table className="table table table-bordered table-hover">
                                <thead className="thead">
                                    <tr>
                                        <th width="200px">Match</th>
                                        <th width="80px"></th>
                                        <th width="80px">In</th>
                                        <th width="80px">Out</th>
                                        <th width="120px">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="sport-name">
                                                <span>
                                                    ICC Cricket World Cup
                                                </span>
                                            </div>
                                            <div className="match-name">
                                                India vs Pakistan
                                            </div>
                                            <div className="match-datetime">
                                                <span className="date">
                                                    20 Sept
                                                </span>{' '}
                                                <span className="time">
                                                    12:00PM
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href="/match-live">
                                                <box-icon name="tv"></box-icon>
                                            </a>
                                        </td>
                                        <td>3.4</td>
                                        <td>3.4</td>
                                        <td>
                                            <span className="status live">
                                                Live
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="sport-name">
                                                <span>
                                                    ICC Cricket World Cup
                                                </span>
                                            </div>
                                            <div className="match-name">
                                                India vs Pakistan
                                            </div>
                                            <div className="match-datetime">
                                                <span className="date">
                                                    20 Sept
                                                </span>{' '}
                                                <span className="time">
                                                    12:00PM
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href="/match-live">
                                                <box-icon name="tv"></box-icon>
                                            </a>
                                        </td>
                                        <td>3.4</td>
                                        <td>3.4</td>
                                        <td>
                                            <span className="status upcoming">
                                                Upcoming
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="sport-name">
                                                <span>
                                                    ICC Cricket World Cup
                                                </span>
                                            </div>
                                            <div className="match-name">
                                                India vs Pakistan
                                            </div>
                                            <div className="match-datetime">
                                                <span className="date">
                                                    20 Sept
                                                </span>{' '}
                                                <span className="time">
                                                    12:00PM
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href="/match-live">
                                                <box-icon name="tv"></box-icon>
                                            </a>
                                        </td>
                                        <td>3.4</td>
                                        <td>3.4</td>
                                        <td>
                                            <span className="status stop">
                                                Stop
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-area mb-4">
                <div className="card-table">
                    <div className="card-header">
                        <div className="header-w-icon">
                            <span>
                                <img
                                    src="assets/images/icon/tennis-racket.png"
                                    alt=""
                                />
                            </span>
                            <h4 className="mb-0">Tenis</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="game-table table-responsive">
                            <table className="table table table-bordered table-hover">
                                <thead className="thead">
                                    <tr>
                                        <th width="200px">Match</th>
                                        <th width="80px"></th>
                                        <th width="80px">In</th>
                                        <th width="80px">Out</th>
                                        <th width="120px">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="sport-name">
                                                <span>
                                                    ICC Cricket World Cup
                                                </span>
                                            </div>
                                            <div className="match-name">
                                                India vs Pakistan
                                            </div>
                                            <div className="match-datetime">
                                                <span className="date">
                                                    20 Sept
                                                </span>{' '}
                                                <span className="time">
                                                    12:00PM
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href="/match-live">
                                                <box-icon name="tv"></box-icon>
                                            </a>
                                        </td>
                                        <td>3.4</td>
                                        <td>3.4</td>
                                        <td>
                                            <span className="status live">
                                                Live
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="sport-name">
                                                <span>
                                                    ICC Cricket World Cup
                                                </span>
                                            </div>
                                            <div className="match-name">
                                                India vs Pakistan
                                            </div>
                                            <div className="match-datetime">
                                                <span className="date">
                                                    20 Sept
                                                </span>{' '}
                                                <span className="time">
                                                    12:00PM
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href="/match-live">
                                                <box-icon name="tv"></box-icon>
                                            </a>
                                        </td>
                                        <td>3.4</td>
                                        <td>3.4</td>
                                        <td>
                                            <span className="status live">
                                                Live
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-area mb-4">
                <div className="card-table">
                    <div className="card-header">
                        <div className="header-w-icon">
                            <span>
                                <img
                                    src="assets/images/icon/football.png"
                                    alt=""
                                />
                            </span>
                            <h4 className="mb-0">Soccer</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="game-table table-responsive">
                            <table className="table table table-bordered table-hover">
                                <thead className="thead">
                                    <tr>
                                        <th width="200px">Match</th>
                                        <th width="80px"></th>
                                        <th width="80px">In</th>
                                        <th width="80px">Out</th>
                                        <th width="120px">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="sport-name">
                                                <span>
                                                    ICC Cricket World Cup
                                                </span>
                                            </div>
                                            <div className="match-name">
                                                India vs Pakistan
                                            </div>
                                            <div className="match-datetime">
                                                <span className="date">
                                                    20 Sept
                                                </span>{' '}
                                                <span className="time">
                                                    12:00PM
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <a href="/match-live">
                                                <box-icon name="tv"></box-icon>
                                            </a>
                                        </td>
                                        <td>3.4</td>
                                        <td>3.4</td>
                                        <td>
                                            <span className="status live">
                                                Live
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="img-cards p-2">
                <div className="row">
                    <div className="col-md-6 p-1">
                        <img
                            src="assets/images/cassino.jpg"
                            alt=""
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-md-6 p-1">
                        <img
                            src="assets/images/cassino.jpg"
                            alt=""
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-md-6 p-1">
                        <img
                            src="assets/images/cassino.jpg"
                            alt=""
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-md-6 p-1">
                        <img
                            src="assets/images/cassino.jpg"
                            alt=""
                            className="img-fluid rounded"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
