import endpoints from '../core/configs/Endpoints.config';
import {
    getRequestWithParams,
    postRequest,
    postRequestWithParams,
    getRequest,
    callApi,
} from '../core/services/ApiService';
import { getStore } from '../core/store';
import { accountService } from '../core/resource/account';

const header = {
    authorization: '',
};

const getAccessTokenData = () => {};

const logoutUser = () => {};

const getPermissions = () => {
    // 1:admin 2:super_admin 3:sub_admin 4:super_master 5:master 6:user 7:close_user
    const permissionsData = {
        inPlay: [6],
        usersList: [1, 2, 3, 4, 5],
        subUserList: [1, 2, 3, 4, 5],
        blockMarket: [1, 2, 3, 4, 5],
        report: [1, 2, 3, 4, 5],
        myMarket: [1, 2, 3, 4, 5, 6],
        menuMyMarket: [6],
        menuProfitLoss: [6],
        menuAccStmt: [1, 2, 3, 4, 5, 6],
        menuBetHist: [6],
        menuLiveBetHist: [6],
        menuIntBetHist: [6],
        menuMatchPnL: [6],
        menuChangePassword: [1, 2, 3, 4, 5, 6],
        menuCassionResult: [6],
    };
    return permissionsData;
};

const isPermission = key => {
    const store = getStore();
    const stateData = store.getState();
    const loginUser = stateData?.auth?.loginRes?.data?.data?.user || null;

    let isShow = false;
    const pList = getPermissions();
    if (pList?.[key]) {
        if (pList[key].indexOf(loginUser?.role_id || 0) !== -1) {
            isShow = true;
        }
    }
    return isShow;
};

const getUserBalBySlug = async params => {
    // const url = `${
    //     endpoints.baseURL.apiUrl
    // }${endpoints.user.getUserBalance.replace(':USER_SLUG', params.user_slug)}`;
    // const options = getRequest();
    // const res = await callApi(url, options, header);
    // return res;
    const res = await accountService.getUserBalance(params);
    console.log({ res });
    return res;
};

export {
    getAccessTokenData,
    logoutUser,
    getPermissions,
    isPermission,
    getUserBalBySlug,
};
