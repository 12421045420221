import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sportActions } from '../../core/resource/sports';
import { AppWrapper } from '../layouts';
import { CustBadge } from '../reusable';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader,
} from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

const MyMarket = () => {
    const dispatch = useDispatch();

    const { isGetAllSportsPending, getAllSportsRes, getAllSportsResError } =
        useSelector(state => state.sport);

    const [sportsList, setSportsList] = useState([]);

    useEffect(() => {
        dispatch(sportActions.getAllSportsRequest());
    }, []);

    useEffect(() => {
        if (!isGetAllSportsPending) {
            if (getAllSportsResError) {
                return;
            }
            if (getAllSportsRes?.data?.data && getAllSportsRes?.data?.success) {
                setSportsList(getAllSportsRes?.data?.data);
            }
        }
    }, [isGetAllSportsPending, getAllSportsRes, getAllSportsResError]);

    const headers = [
        {
            isFilterable: false,
            isSortable: false,
            prop: 'sno',
            title: 'S.No.',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'title',
            title: 'Title',
        },
    ];

    return (
        <AppWrapper>
            <div className="container py-5">
                <h3 className="mb-0 mt-1 me-4">My Market</h3>
                <DatatableWrapper
                    className="table"
                    headers={headers}
                    body={sportsList}>
                    <Row className="mb-4">
                        <Col
                            className="d-flex flex-col justify-content-end align-items-end"
                            lg={4}
                            xs={12}>
                            <Filter />
                        </Col>
                    </Row>
                    <Table responsive bordered hover striped>
                        <TableHeader />
                        <TableBody>
                            {sportsList.map((row, rowIdx) => {
                                const statusText =
                                    row.status === 1 ? 'On' : 'Off';
                                return (
                                    <tr key={rowIdx}>
                                        <td>{rowIdx + 1}</td>
                                        <td>{row.title}</td>
                                    </tr>
                                );
                            })}
                        </TableBody>
                    </Table>
                </DatatableWrapper>
            </div>
        </AppWrapper>
    );
};

export default MyMarket;
