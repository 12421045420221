import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { CustButton } from '../reusable';

const ChangePasswordModal = props => {
    const { isShow, toggleModal, handleOnChangePassword, backdrop } = props;
    const { isChangePasswordPending } = useSelector(state => state.user);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const handleOnChange = e => {
        const { name, value } = e.target;
        switch (name) {
            case 'currentPassword':
                setCurrentPassword(value);
                break;
            case 'newPassword':
                setNewPassword(value);
                break;
            default:
                console.log(name, value);
                break;
        }
    };

    const handleOnSubmit = () => {
        handleOnChangePassword({
            password: newPassword,
            repeat_password: currentPassword,
        });
    };

    return (
        <Modal
            show={isShow}
            onHide={toggleModal}
            backdrop={backdrop || true}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="row g-3">
                    <div className="col-md-12">
                        <label htmlFor="inputEmail4" className="form-label">
                            Current Password
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            id="inputEmail4"
                            name="currentPassword"
                            value={currentPassword}
                            onChange={handleOnChange}
                        />
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="inputEmail4" className="form-label">
                            New Password
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            id="inputEmail4"
                            name="newPassword"
                            value={newPassword}
                            onChange={handleOnChange}
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer className="two-btns-footer">
                <CustButton
                    onClick={toggleModal}
                    text={'Cancel'}
                    variant="danger"
                    className="cancel-btn"
                />
                <CustButton
                    onClick={handleOnSubmit}
                    isLoader={isChangePasswordPending}
                    text={'Save'}
                    className="save-btn"
                />
            </Modal.Footer>
        </Modal>
    );
};

export default ChangePasswordModal;
