import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const CustLoader = props => {
    const { spinnerProps, isShow } = props;
    if (!isShow) {
        return null;
    }
    return (
        <div className="pg-loader">
            <div className="loader-div text-center">
                <Spinner animation="border" variant="primary" {...spinnerProps}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <div className="loading-txt">Loading..</div>
            </div>
        </div>
    );
};

export default CustLoader;
