import React from 'react';
import { useSelector } from 'react-redux';
import Header from '../../pages/sections/header';
import Footer from '../../pages/sections/footer';
import { CustToast, CustLoader } from '../reusable';

const AppWrapper = props => {
    const { children, isSpinner } = props;

    const { isGetUsersPending } = useSelector(state => state.user);
    const { isGetAllSportsPending } = useSelector(state => state.sport);
    const { isGetUserLadgerPending } = useSelector(state => state.account);
    const { isGetAnnouncementsPending } = useSelector(state => state.generic);
    const isLoader =
        isSpinner ||
        isGetUsersPending ||
        isGetAllSportsPending ||
        isGetUserLadgerPending ||
        isGetAnnouncementsPending ||
        false;
    return (
        <>
            <CustToast />
            <Header />
            <CustLoader isShow={isLoader} />
            <div className="page-body-area">{children}</div>
            <Footer />
        </>
    );
};

export default AppWrapper;
