// import { all, call, put, select, take } from 'redux-saga/effects';
import {
    take,
    put,
    call,
    all,
    takeLatest,
    takeEvery,
} from 'redux-saga/effects';
import { userActions as actions, userService } from './index';
import { notificationActions } from '../notification';

function* handleAddUserRequest() {
    const action = yield take(actions.addUserRequest.getType());
    const dataParams = action.payload;

    let res;
    try {
        res = yield call(userService.addUser, dataParams);
        if (res.isError) {
            yield put(actions.addUserError(res));
            yield put(
                notificationActions.openSnackbar({
                    text: res.errorMsg || res.errorMessage,
                    alertType: 'danger',
                }),
            );
        } else {
            yield put(actions.addUserSuccess(res));
            yield put(
                notificationActions.openSnackbar({
                    text: res?.data?.message || 'Success',
                    alertType: 'success',
                }),
            );
        }
    } catch (error) {
        yield put(actions.addUserError());
    }
}

function* handleUserDeleteRequest() {
    const action = yield take(actions.deleteUserRequest.getType());
    const dataParams = action.payload;

    let res = null;
    try {
        res = yield call(userService.deleteUser, dataParams);

        if (res.isError) {
            yield put(actions.deleteUserError(res));
        } else {
            yield put(actions.deleteUserSuccess(res.data));
        }
    } catch (error) {
        yield put(actions.deleteUserError(res));
    }
}

function* handleGetUsersRequest() {
    const action = yield take(actions.getUsersRequest.getType());
    const dataParams = action.payload;

    let res;
    try {
        res = yield call(userService.getUsers, dataParams);
        if (res.isError) {
            yield put(actions.getUsersError(res));
        } else {
            yield put(actions.getUsersSuccess(res));
        }
    } catch (error) {
        yield put(actions.getUsersError());
    }
}

function* handleGetRolesRequest() {
    const action = yield take(actions.getRolesRequest.getType());
    const dataParams = action.payload;

    let res;
    try {
        res = yield call(userService.getRoles, dataParams);
        if (res.isError) {
            yield put(actions.getRolesError(res));
        } else {
            yield put(actions.getRolesSuccess(res));
        }
    } catch (error) {
        yield put(actions.getRolesError());
    }
}

function* handleChangePasswordRequest() {
    const action = yield take(actions.changePasswordRequest.getType());
    const dataParams = action.payload;

    let res;
    try {
        res = yield call(userService.changePassword, dataParams);
        if (res.isError) {
            yield put(actions.changePasswordError(res));
            yield put(
                notificationActions.openSnackbar({
                    text: res.errorMsg || res.errorMessage,
                    alertType: 'danger',
                }),
            );
        } else {
            yield put(actions.changePasswordSuccess(res));
            yield put(
                notificationActions.openSnackbar({
                    text: res?.data?.message || 'Success',
                    alertType: 'success',
                }),
            );
        }
    } catch (error) {
        yield put(actions.changePasswordError());
    }
}

function* handleUpdateUserStatusRequest() {
    const action = yield take(actions.updateUserStatusRequest.getType());
    const dataParams = action.payload;

    let res;
    try {
        res = yield call(userService.updateUserStatus, dataParams);
        if (res.isError) {
            yield put(actions.updateUserStatusError(res));
            yield put(
                notificationActions.openSnackbar({
                    text: res.errorMsg || res.errorMessage,
                    alertType: 'danger',
                }),
            );
        } else {
            yield put(actions.updateUserStatusSuccess(res));
            yield put(
                notificationActions.openSnackbar({
                    text: res?.data?.message || 'Success',
                    alertType: 'success',
                }),
            );
        }
    } catch (error) {
        yield put(actions.updateUserStatusError());
    }
}

function* handleAddUserRequestWatcher() {
    yield takeEvery(actions.addUserRequest, handleAddUserRequest);
}

function* handleUserDeleteRequestWatcher() {
    yield takeEvery(actions.deleteUserRequest, handleUserDeleteRequest);
}

function* handleGetUsersRequestWatcher() {
    yield takeEvery(actions.getUsersRequest, handleGetUsersRequest);
}

function* handleGetRolesRequestWatcher() {
    yield takeEvery(actions.getRolesRequest, handleGetRolesRequest);
}

function* handleChangePasswordRequestWatcher() {
    yield takeEvery(actions.changePasswordRequest, handleChangePasswordRequest);
}

function* handleUpdateUserStatusRequestWatcher() {
    yield takeEvery(
        actions.updateUserStatusRequest,
        handleUpdateUserStatusRequest,
    );
}

function* rootInvoiceSaga() {
    yield all([
        handleAddUserRequest(),
        handleUserDeleteRequest(),

        handleAddUserRequestWatcher(),
        handleUserDeleteRequestWatcher(),

        // handleGetUsersRequest(),
        handleGetUsersRequestWatcher(),

        handleGetRolesRequest(),
        handleGetRolesRequestWatcher(),

        handleChangePasswordRequest(),
        handleChangePasswordRequestWatcher(),

        handleUpdateUserStatusRequest(),
        handleUpdateUserStatusRequestWatcher(),
    ]);
}

export default rootInvoiceSaga;
