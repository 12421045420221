// import { Link } from 'react-router-dom'
// import Menubar from "./sections/menubar"
import React from 'react';
import Header from './sections/header';
import Footer from './sections/footer';
import Dashboard from './layouts/dashboard';

function Index() {
    return (
        <>
            <Header />
            <section className="banner">
                <img
                    src="assets/images/cricket_2.jpg"
                    alt=""
                    className="img-fluid"
                />
            </section>
            <main className="page-body-area py-4">
                <Dashboard />
            </main>
            <Footer />
        </>
    );
}

export default Index;
