import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../core/resource/auth';
import { userActions } from '../../core/resource/users';
import { genericActions } from '../../core/resource/generic';
import { accountActions } from '../../core/resource/account';
import Menubar from './menubar';
import Dropdown from 'react-bootstrap/Dropdown';
import { ChangePasswordModal, MenuOptionsModal } from '../modals';

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //
    const loggedUserData = useSelector(
        state => state.auth?.loginRes?.data?.data?.user || null,
    );

    const {
        isGetUserBalancePending,
        getUserBalanceRes,
        getUserBalanceResError,
    } = useSelector(state => state.account);
    const {
        isChangePasswordPending,
        changePasswordRes,
        changePasswordResError,
    } = useSelector(state => state.user);
    const {
        isGetAnnouncementsPending,
        getAnnouncementsRes,
        getAnnouncementsResError,
    } = useSelector(state => state.generic);

    //
    const [balanceData, setBalanceData] = useState(null);
    const [announcementText, setAnnouncementText] = useState('Welcome');
    const [isShowMenuOptionsModal, setIsShowMenuOptionsModal] = useState(false);
    const [isShowChangePasswordModal, setIsShowChangePasswordModal] =
        useState(false);

    useEffect(() => {
        if (loggedUserData) {
            dispatch(userActions.getRolesRequest());
            dispatch(genericActions.getAnnouncementsRequest());
            dispatch(
                accountActions.getUserBalanceRequest({
                    user_slug: loggedUserData?.username || '',
                }),
            );
        }
    }, []);

    useEffect(() => {
        if (!isGetUserBalancePending) {
            if (getUserBalanceResError) {
                return;
            }
            if (
                getUserBalanceRes?.data?.data &&
                getUserBalanceRes?.data?.success
            ) {
                setBalanceData(getUserBalanceRes.data.data);
            }
        }
    }, [isGetUserBalancePending, getUserBalanceRes, getUserBalanceResError]);

    useEffect(() => {
        if (!isChangePasswordPending) {
            if (changePasswordResError) {
                return;
            }
            if (
                changePasswordRes?.data?.data &&
                changePasswordRes?.data?.success
            ) {
                toggleChangePasswordModal();
                dispatch(
                    userActions.handleClearUserStore('CHANGE_PASSWORD_RES'),
                );
            }
        }
    }, [isChangePasswordPending, changePasswordRes, changePasswordResError]);

    useEffect(() => {
        if (!isGetAnnouncementsPending) {
            if (getAnnouncementsResError) {
                return;
            }
            if (
                getAnnouncementsRes?.data?.data &&
                getAnnouncementsRes?.data?.success
            ) {
                const datalist = getAnnouncementsRes.data.data;
                setAnnouncementText(datalist[0].title);
            }
        }
    }, [
        isGetAnnouncementsPending,
        getAnnouncementsRes,
        getAnnouncementsResError,
    ]);

    const handleActionItem = action => {
        console.log({ action });
        switch (action) {
            case 'logout':
                dispatch(authActions.handleClearAuthStore('LOGIN_RES'));
                navigate('/');
                break;
            case 'changePassword':
                toggleChangePasswordModal();
                break;
            default:
                navigate(`/${action}`);
                break;
        }
        setIsShowMenuOptionsModal(false);
    };

    const toggleChangePasswordModal = () => {
        setIsShowChangePasswordModal(!isShowChangePasswordModal);
    };

    const toggleMenuOptionsModal = () => {
        setIsShowMenuOptionsModal(!isShowMenuOptionsModal);
    };

    const handleOnChangePassword = params => {
        dispatch(userActions.changePasswordRequest(params));
    };

    return (
        <>
            <header id="header">
                <div className="header-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-3 position-relative">
                                <div className="header-logo">
                                    <div className="logo">
                                        <a href="/">
                                            <img
                                                src="/assets/images/24x7exch.png"
                                                alt=""
                                                className="img-fluid"
                                            />
                                            {/* <!-- <h3 className="text-center text-white mb-0">Betking</h2> --> */}
                                        </a>
                                    </div>
                                </div>
                                <div className="logo-text d-none d-md-block">
                                    24x7exch
                                </div>
                            </div>

                            <div className="col-12 col-md-9 header-top-menu text-end d-flex justify-content-end">
                                <div className="d-none d-lg-block marquee-slider">
                                    <marquee>
                                        <ul className="mb-0">
                                            <li
                                                onClick={() =>
                                                    handleActionItem(
                                                        'announcements',
                                                    )
                                                }>
                                                {announcementText}
                                            </li>
                                        </ul>
                                    </marquee>
                                </div>
                                <div className="header-top-right">
                                    <ul className="d-flex justify-content-end">
                                        {loggedUserData && (
                                            <>
                                                <li className="d-none d-md-block">
                                                    <a href="/">Home</a>
                                                </li>
                                                <li className="d-none d-md-block">
                                                    <a
                                                        className="balance"
                                                        href="/">
                                                        BAL :{' '}
                                                        {balanceData?.exposure ||
                                                            '0.00'}
                                                    </a>
                                                </li>
                                                <li className="d-none d-md-block">
                                                    <a
                                                        className="pl-balance"
                                                        href="/">
                                                        L : 0.00
                                                    </a>
                                                </li>

                                                <li>
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            className="user-dropdown"
                                                            onClick={
                                                                toggleMenuOptionsModal
                                                            }>
                                                            <span className="user-icon"></span>
                                                            <span
                                                                className="user-txt"
                                                                onClick={
                                                                    toggleMenuOptionsModal
                                                                }>
                                                                {loggedUserData?.username ||
                                                                    'User'}
                                                            </span>
                                                        </Dropdown.Toggle>

                                                        {/* <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    handleActionItem(
                                                                        'ladger',
                                                                    )
                                                                }>
                                                                Account
                                                                Statement
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    handleActionItem(
                                                                        'changePassword',
                                                                    )
                                                                }>
                                                                Change Password
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    handleActionItem(
                                                                        'logout',
                                                                    )
                                                                }>
                                                                Log Out
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu> */}
                                                    </Dropdown>
                                                </li>
                                            </>
                                        )}
                                        {!loggedUserData && (
                                            <li
                                                onClick={() =>
                                                    handleActionItem('login')
                                                }>
                                                <span className="login-btn">
                                                    Login
                                                </span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Menubar />
            </header>

            <ChangePasswordModal
                isShow={isShowChangePasswordModal}
                backdrop="static"
                toggleModal={toggleChangePasswordModal}
                handleOnChangePassword={handleOnChangePassword}
            />
            <MenuOptionsModal
                isShow={isShowMenuOptionsModal}
                backdrop="static"
                toggleModal={toggleMenuOptionsModal}
                loggedUserData={loggedUserData}
                handleActionItem={handleActionItem}
            />
        </>
    );
};

export default Header;
