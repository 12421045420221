import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { accountActions } from '../../core/resource/account';
import { AppWrapper } from '../layouts';
import {
    DatatableWrapper,
    Filter,
    TableBody,
    TableHeader,
    Pagination,
    PaginationOptions,
} from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { defaultPerPageRecords } from '../../core/configs/Constants.config';

const ProfitLossMatch = () => {
    const dispatch = useDispatch();
    const routeParams = useParams();

    const { isGetUserLadgerPending, getUserLadgerRes, getUserLadgerResError } =
        useSelector(state => state.account);

    const [page, setPage] = useState(1);
    const [perPageRecords, setPerPageRecords] = useState(defaultPerPageRecords);
    const [totalRecords, setTotalRecords] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [ladgerList, setLedgerList] = useState([]);
    const [totalLedger, setTotalLedger] = useState(0);

    useEffect(() => {
        getLedger();
    }, []);

    useEffect(() => {
        getLedger();
    }, [startDate, endDate]);

    useEffect(() => {
        if (!isGetUserLadgerPending) {
            if (getUserLadgerResError) {
                return;
            }
            if (
                getUserLadgerRes?.data?.data &&
                getUserLadgerRes?.data?.success
            ) {
                setLedgerList(getUserLadgerRes.data.data?.ledger || []);
                setTotalLedger(getUserLadgerRes.data.data?.total_count || 0);
            }
        }
    }, [isGetUserLadgerPending, getUserLadgerRes, getUserLadgerResError]);

    const getMaxPage = () => {
        const mNum = Math.ceil(totalRecords / perPageRecords);
        return mNum;
    };
    const handleOnPageChange = pNum => {
        console.log({ pNum });
        setPage(pNum);
    };

    const handleOnPerPageChange = value => {
        setPerPageRecords(value);
    };

    const getLedger = () => {
        dispatch(
            accountActions.getUserLadgerRequest({
                page: 1,
                show: 100,
                user_slug: 'admin',
            }),
        );
    };

    const headers = [
        {
            isFilterable: false,
            isSortable: false,
            prop: 'sno',
            title: 'S.No.',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'event_name',
            title: 'Event Name',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'report',
            title: 'Report',
        },
    ];

    return (
        <AppWrapper>
            <div className="container py-5">
                <h3 className="mb-0 mt-1 me-4">Profit Loss Listing</h3>
                <div className="custom-table-filter mb-3">
                    <Row className="">
                        <div className="d-flex flex-col align-items-center mb-2 mb-sm-0 col-md-6 col-sm-12"></div>
                    </Row>
                </div>
                <DatatableWrapper
                    className="table"
                    body={ladgerList}
                    checkboxProps={{
                        onCheckboxChange: function noRefCheck() {},
                    }}
                    headers={headers}
                    sortProps={{
                        sortValueObj: {
                            date: function noRefCheck() {},
                        },
                    }}>
                    <Row className="mb-4">
                        <Col
                            className="d-flex flex-col justify-content-end align-items-end"
                            lg={4}
                            xs={12}>
                            <Filter />
                        </Col>
                    </Row>
                    <Table responsive bordered hover striped>
                        <TableHeader />
                        <TableBody>
                            {ladgerList.map((item, rowIdx) => {
                                return (
                                    <tr key={rowIdx}>
                                        <td>{rowIdx + 1}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                );
                            })}
                            {ladgerList?.length === 0 && (
                                <tr>
                                    <td colSpan={headers?.length || 0}>
                                        No Records found
                                    </td>
                                </tr>
                            )}
                        </TableBody>
                    </Table>
                    <div className="table-pagination">
                        <Row className="mb-4">
                            <Col
                                className="d-flex flex-col align-items-center mb-2 mb-sm-0"
                                lg={3}
                                sm={3}
                                xs={12}>
                                <PaginationOptions
                                    alwaysShowPagination
                                    controlledProps={{
                                        rowsPerPage: perPageRecords,
                                        /** Number of rows shown per page options. */
                                        rowsPerPageOptions: [5, 10, 15, 20],
                                        // /**
                                        //  * The filtered data length. When not using filter control,
                                        //  * then this should equal to the table body's length.
                                        //  */
                                        filteredDataLength: perPageRecords,
                                        /** The function fired when any of the pagination option is changed. */
                                        onRowsPerPageChange: value =>
                                            handleOnPerPageChange(value),
                                    }}
                                />
                            </Col>
                            <Col
                                className="d-flex flex-col justify-content-end align-items-end"
                                lg={9}
                                sm={9}
                                xs={12}>
                                <Pagination
                                    alwaysShowPagination
                                    paginationRange={2}
                                    controlledProps={{
                                        currentPage: page,
                                        maxPage: getMaxPage(),
                                        onPaginationChange: pNum =>
                                            handleOnPageChange(pNum),
                                    }}
                                />
                            </Col>
                            <Col className="mt-2" xs={12}>
                                {/* <BulkCheckboxControl /> */}
                            </Col>
                        </Row>
                    </div>
                </DatatableWrapper>
            </div>
        </AppWrapper>
    );
};

export default ProfitLossMatch;
