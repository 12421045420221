import endpoints from '../../configs/Endpoints.config';
import { getAxiosConfig, getFomatedError } from '../../services/ApiService';

const loginUser = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${endpoints.baseURL.apiUrl}${endpoints.auth.login}`,
        method: 'POST',
        data: params,
        headers: { 'Content-Type': 'application/json' },
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });
    return response;
};

const signupUser = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${endpoints.baseURL.apiUrl}${endpoints.user.create}`,
        method: 'POST',
        data: params,
        headers: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });
    return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    loginUser,
    signupUser,
};
