import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { accountActions } from '../../core/resource/account';
import { AppWrapper } from '../layouts';
import {
    DatatableWrapper,
    Filter,
    TableBody,
    TableHeader,
} from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import 'boxicons';

const AccountStatement = () => {
    const dispatch = useDispatch();
    const routeParams = useParams();

    const { isGetUserLadgerPending, getUserLadgerRes, getUserLadgerResError } =
        useSelector(state => state.account);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [ladgerList, setLedgerList] = useState([]);
    const [totalLedger, setTotalLedger] = useState(0);

    useEffect(() => {
        getLedger();
    }, []);

    useEffect(() => {
        getLedger();
    }, [startDate, endDate]);

    useEffect(() => {
        if (!isGetUserLadgerPending) {
            if (getUserLadgerResError) {
                return;
            }
            if (
                getUserLadgerRes?.data?.data &&
                getUserLadgerRes?.data?.success
            ) {
                setLedgerList(getUserLadgerRes.data.data?.ledger || []);
                setTotalLedger(getUserLadgerRes.data.data?.total_count || 0);
            }
        }
    }, [isGetUserLadgerPending, getUserLadgerRes, getUserLadgerResError]);

    const getLedger = () => {
        dispatch(
            accountActions.getUserLadgerRequest({
                page: 1,
                show: 100,
                user_slug: 'admin',
            }),
        );
    };

    const headers = [
        {
            isFilterable: false,
            isSortable: false,
            prop: 'sno',
            title: 'S.No.',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'date',
            title: 'Date',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'description',
            title: 'Description',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'credit',
            title: 'Credit',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'debit',
            title: 'Debit',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'commission',
            title: 'Commission',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'balance',
            title: 'Balance',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'remark',
            title: 'remarks',
        },
    ];

    return (
        <AppWrapper>
            <div className="container py-5">
                <Row className="">
                    <Col className="col-8 col-md-9 col-lg-10">
                        <h3 className="mb-0 mt-1 me-4">Account Statement</h3>
                    </Col>
                    <Col className="col-4 col-md-3 col-lg-2 text-end">
                        <button className="btn back-btn btn-link">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24">
                                <path d="m12.707 7.707-1.414-1.414L5.586 12l5.707 5.707 1.414-1.414L8.414 12z"></path>
                                <path d="M16.293 6.293 10.586 12l5.707 5.707 1.414-1.414L13.414 12l4.293-4.293z"></path>
                            </svg>
                            Go Back
                        </button>
                    </Col>
                </Row>

                <div className="custom-table-filter mb-3">
                    <Row className="">
                        <div className="d-flex flex-col align-items-center col-md-12 col-sm-12">
                            <div className="table-filterlist d-flex align-items-start flex-wrap">
                                <Form.Check
                                    inline
                                    label="Accounts"
                                    name="group1"
                                    type="checkbox"
                                    id={`inline-checkbox`}
                                />
                                <Form.Control
                                    type="date"
                                    size="sm"
                                    className="me-2"
                                />
                                <Form.Control
                                    type="date"
                                    size="sm"
                                    className="me-2"
                                />
                                <div className="search-block me-2">
                                    <Form.Control
                                        placeholder="Search here"
                                        size="sm"
                                    />
                                </div>
                                <Button
                                    type="submit"
                                    variant="secondary"
                                    size="sm">
                                    FILTER
                                </Button>
                            </div>
                        </div>
                    </Row>
                </div>
                <DatatableWrapper
                    className="table"
                    body={ladgerList}
                    checkboxProps={{
                        onCheckboxChange: function noRefCheck() {},
                    }}
                    headers={headers}
                    sortProps={{
                        sortValueObj: {
                            date: function noRefCheck() {},
                        },
                    }}>
                    <Table responsive bordered hover striped>
                        <TableHeader />
                        <TableBody>
                            {/* {ladgerList.map((item, rowIdx) => {
                                return (
                                    <tr key={rowIdx}>
                                        <td>{rowIdx + 1}</td>
                                        <td>22/10/2023, 16:00:08</td>
                                        <td>
                                            <div className="">
                                                Chip Credied to test user 1(tu1)
                                                by amitji(dhdh100)- Done By -
                                                dhdh100(amitji)
                                            </div>
                                        </td>
                                        <td>0</td>
                                        <td>1000</td>
                                        <td>0</td>
                                        <td>49000</td>
                                        <td>-</td>
                                    </tr>
                                );
                            })}
                            {ladgerList?.length === 0 && (
                                <tr>
                                    <td colSpan={headers?.length || 0}>
                                        No Records found
                                    </td>
                                </tr>
                            )} */}
                            <tr>
                                <td>1</td>
                                <td>22/10/2023, 16:00:08</td>
                                <td>
                                    <div className="">
                                        Chip Credied to test user 1(tu1) by
                                        amitji(dhdh100)- Done By -
                                        dhdh100(amitji)
                                    </div>
                                </td>
                                <td className="credit-cell">
                                    <span className="credit-value">0</span>
                                </td>
                                <td className="debit-cell">
                                    <span className="debit-value">1000</span>
                                </td>
                                <td className="commision-cell">
                                    <span className="commission-value">
                                        1000
                                    </span>
                                </td>
                                <td className="balance-cell">
                                    <span className="balance-value">49000</span>
                                </td>
                                <td className="remark-cell">-</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>22/10/2023, 16:00:08</td>
                                <td>
                                    <div className="">
                                        Chip Credied to test user 1(tu1) by
                                        amitji(dhdh100)- Done By -
                                        dhdh100(amitji)
                                    </div>
                                </td>
                                <td className="credit-cell">
                                    <span className="credit-value">0</span>
                                </td>
                                <td className="debit-cell">
                                    <span className="debit-value">1000</span>
                                </td>
                                <td className="commision-cell">
                                    <span className="commission-value">
                                        1000
                                    </span>
                                </td>
                                <td className="balance-cell">
                                    <span className="balance-value">49000</span>
                                </td>
                                <td className="remark-cell">-</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>22/10/2023, 16:00:08</td>
                                <td>
                                    <div className="">
                                        Chip Credied to test user 1(tu1) by
                                        amitji(dhdh100)- Done By -
                                        dhdh100(amitji)
                                    </div>
                                </td>
                                <td className="credit-cell">
                                    <span className="credit-value">0</span>
                                </td>
                                <td className="debit-cell">
                                    <span className="debit-value">1000</span>
                                </td>
                                <td className="commision-cell">
                                    <span className="commission-value">
                                        1000
                                    </span>
                                </td>
                                <td className="balance-cell">
                                    <span className="balance-value">49000</span>
                                </td>
                                <td className="remark-cell">-</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>22/10/2023, 16:00:08</td>
                                <td>
                                    <div className="">
                                        Chip Credied to test user 1(tu1) by
                                        amitji(dhdh100)- Done By -
                                        dhdh100(amitji)
                                    </div>
                                </td>
                                <td className="credit-cell">
                                    <span className="credit-value">0</span>
                                </td>
                                <td className="debit-cell">
                                    <span className="debit-value">1000</span>
                                </td>
                                <td className="commision-cell">
                                    <span className="commission-value">
                                        1000
                                    </span>
                                </td>
                                <td className="balance-cell">
                                    <span className="balance-value">49000</span>
                                </td>
                                <td className="remark-cell">-</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>22/10/2023, 16:00:08</td>
                                <td>
                                    <div className="">
                                        Chip Credied to test user 1(tu1) by
                                        amitji(dhdh100)- Done By -
                                        dhdh100(amitji)
                                    </div>
                                </td>
                                <td className="credit-cell">
                                    <span className="credit-value">0</span>
                                </td>
                                <td className="debit-cell">
                                    <span className="debit-value">1000</span>
                                </td>
                                <td className="commision-cell">
                                    <span className="commission-value">
                                        1000
                                    </span>
                                </td>
                                <td className="balance-cell">
                                    <span className="balance-value">49000</span>
                                </td>
                                <td className="remark-cell">-</td>
                            </tr>
                        </TableBody>
                    </Table>
                </DatatableWrapper>
            </div>
        </AppWrapper>
    );
};

export default AccountStatement;
