import axios from 'axios';
import { getAccessTokenData, logoutUser } from '../../utils/AxoisHelper';
import { getStore } from '../../core/store';

let headers = {
    Authorization: '',
    token_type: '',
    account_id: '',
};

const getRequest = () => ({ method: 'GET' });
const getRequestWithParams = data => ({ method: 'GET', params: data });
const postRequest = data => ({ method: 'POST', data });
const postRequestWithParams = data => ({ method: 'POST', params: data });

const checkTokenIsValid = async res => {
    const dataMsg = res?.data?.message?.toLowerCase() || '';
    const dataStatus = res?.data?.status || '';
    const errorMsgArr = [
        'invalid token',
        'token is missing',
        'token is expired',
        'token is expired.',
        'token not found in db.',
        'invalid token.',
    ];
    //console.log('--checkTokenIsValid--', {dataStatus, dataMsg});
    if (
        dataStatus !== 200 &&
        errorMsgArr.indexOf(dataMsg?.toLowerCase()) !== -1
    ) {
        await logoutUser();
    }
};
const callApi = async (url, options, hearderParams) => {
    const store = getStore();
    const stateData = store.getState();
    const loginUser = stateData?.auth?.loginRes?.data?.data?.user || null;
    let res = null;
    try {
        const { authorization } = hearderParams;
        headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            account_id: '',
            token_type: '',
            Authorization: '',
        };
        if (loginUser?.token || authorization) {
            headers['Authorization'] = 'Bearer ' + loginUser?.token || '';
        }

        res = await axios({ url, ...options, headers });
        res = await res.data;
        return res;
    } catch (error) {
        res = { isError: true, msg: error.message };
        return res;
    }
};

const getAxiosConfig = dataParams => {
    const store = getStore();
    const stateData = store.getState();
    const loginUser = stateData?.auth?.loginRes?.data?.data?.user || null;
    if (
        dataParams['Cache-Control'] !== undefined &&
        dataParams['Cache-Control'] !== null
    ) {
        headers['Pragma'] = dataParams['Cache-Control'];
    } else {
        delete headers['Cache-Control'];
    }
    if (dataParams['Accept'] !== undefined) {
        headers['Content-Type'] = dataParams['Content-Type'];
    } else {
        headers['Content-Type'] = 'application/json';
    }
    headers['Accept'] = 'application/json';
    if (loginUser?.token) {
        headers['Authorization'] = 'Bearer ' + loginUser?.token || '';
        //headers['token'] = 'Bearer ' + loginUser?.token || '';
    }

    const { type } = dataParams;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let config = { baseURL: '', cancelToken: source.token };
    if (type === 'upload') {
        config = {
            baseURL: '',
            cancelToken: source.token,
            mode: 'no-cors',
            headers,
            withCredentials: true,
            onUploadProgress() {},
        };
    } else if (type === 'download') {
        config = {
            baseURL: '',
            cancelToken: source.token,
            mode: 'no-cors',
            headers,
            onDownloadProgress() {},
        };
    }

    const axiosInstance = axios.create(config);
    axiosInstance.interceptors.response.use(function (response) {
        // console.log('--interceptors axiosInstance--', response);
        checkTokenIsValid(response);
        return response;
    });
    return { axiosInstance, source, config };
};

const getFomatedError = err => {
    const { code, message, name, response } = err;
    let resMsg = response?.data?.message || message || '';
    if (resMsg === 'Validation Error.') {
        const data = response?.data?.data || [];
        const keys = Object.keys(data);
        if (keys?.length > 0) {
            resMsg = data[keys[0]][0];
        }
    }
    const error = {
        isError: true,
        errorCode: code,
        errorMessage: resMsg,
        errorName: name,
    };
    return error;
};

export {
    getRequest,
    getRequestWithParams,
    postRequest,
    postRequestWithParams,
    callApi,
    getAxiosConfig,
    getFomatedError,
    headers,
};
