import { createReducer } from 'redux-act';
import actions from './genericActions';

const initialState = {
    isGetAnnouncementsPending: false,
    getAnnouncementsRes: null,
    getAnnouncementsResError: null,
};

//
const handleGetAnnouncementsRequest = state => {
    return {
        ...state,
        ...{
            isGetAnnouncementsPending: true,
            getAnnouncementsRes: null,
            getAnnouncementsResError: null,
        },
    };
};
const handleGetAnnouncementsSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isGetAnnouncementsPending: false,
            getAnnouncementsRes: payload,
            getAnnouncementsResError: null,
        },
    };
};
const handleGetAnnouncementsError = (state, payload) => ({
    ...state,
    ...{
        isGetAnnouncementsPending: false,
        getAnnouncementsRes: null,
        getAnnouncementsResError: payload,
    },
});

//
const clearGenericStore = (state, payload) => {
    switch (payload) {
        case 'ANNOUNCEMENTS_RES':
            return {
                ...state,
                ...{
                    isGetAnnouncementsPending: false,
                    getAnnouncementsRes: null,
                    getAnnouncementsResError: null,
                },
            };
        default:
            return state;
    }
};
export default createReducer(
    {
        [actions.getAnnouncementsRequest]: handleGetAnnouncementsRequest,
        [actions.getAnnouncementsSuccess]: handleGetAnnouncementsSuccess,
        [actions.getAnnouncementsError]: handleGetAnnouncementsError,

        [actions.handleClearGenericStore]: clearGenericStore,
    },
    initialState,
);
