import endpoints from '../../configs/Endpoints.config';
import { getAxiosConfig, getFomatedError } from '../../services/ApiService';
import { defaultPerPageRecords } from '../../configs/Constants.config';

const getUserBalance = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${
            endpoints.baseURL.apiUrl
        }${endpoints.account.getUserBalance.replace(
            ':USER_SLUG',
            params.user_slug,
        )}`,
        method: 'GET',
        headers: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });
    return response;
};

const getUserLadger = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${
            endpoints.baseURL.apiUrl
        }${endpoints.account.getUserLadger.replace(
            ':USER_SLUG',
            params.user_slug,
        )}`,
        params: {
            page: params?.page || 1,
            show: params?.show || defaultPerPageRecords,
        },
        method: 'GET',
        headers: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });
    return response;
};

const chipsDeposit = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${endpoints.baseURL.apiUrl}${endpoints.account.chipDeposit}`,
        data: params,
        method: 'POST',
        headers: {},
    })
        .then(res => {
            console.log({ res });
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });
    return response;
};

const chipsWithdraw = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${endpoints.baseURL.apiUrl}${endpoints.account.chipWithdraw}`,
        data: params,
        method: 'POST',
        headers: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });
    return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getUserBalance,
    getUserLadger,
    chipsDeposit,
    chipsWithdraw,
};
