// import { all, call, put, select, take } from 'redux-saga/effects';
import { take, put, call, all, takeEvery } from 'redux-saga/effects';
import { sportActions as actions, sportService } from './index';

function* handleGetAllSportsRequest() {
    const action = yield take(actions.getAllSportsRequest.getType());
    const dataParams = action.payload;

    let res;
    try {
        res = yield call(sportService.getAllSports, dataParams);
        if (res.isError) {
            yield put(actions.getAllSportsError(res));
        } else {
            yield put(actions.getAllSportsSuccess(res));
        }
    } catch (error) {
        yield put(actions.getAllSportsError());
    }
}

function* handleAddUpdateUserSportRequest() {
    const action = yield take(actions.addUpdateSportRequest.getType());
    const dataParams = action.payload;

    let res;
    try {
        res = yield call(sportService.addUpdateSport, dataParams);
        if (res.isError) {
            yield put(actions.addUpdateSportError(res));
        } else {
            yield put(actions.addUpdateSportSuccess(res));
        }
    } catch (error) {
        yield put(actions.addUpdateSportError());
    }
}

function* handleGetUserSportRequest() {
    const action = yield take(actions.getUserSportsRequest.getType());
    const dataParams = action.payload;

    let res;
    try {
        res = yield call(sportService.getUserSports, dataParams);
        if (res.isError) {
            yield put(actions.getUserSportsError(res));
        } else {
            yield put(actions.getUserSportsSuccess(res));
        }
    } catch (error) {
        yield put(actions.getUserSportsError());
    }
}

function* handleGetAllSportsRequestWatcher() {
    yield takeEvery(actions.getAllSportsRequest, handleGetAllSportsRequest);
}

function* handleGetUserSportRequestWatcher() {
    yield takeEvery(actions.getUserSportsRequest, handleGetUserSportRequest);
}

function* handleAddUpdateUserSportRequestWatcher() {
    yield takeEvery(
        actions.addUpdateSportRequest,
        handleAddUpdateUserSportRequest,
    );
}

function* rootSportSaga() {
    yield all([
        handleGetAllSportsRequest(),
        handleGetAllSportsRequestWatcher(),
        handleGetUserSportRequest(),
        handleGetUserSportRequestWatcher(),
        handleAddUpdateUserSportRequest(),
        handleAddUpdateUserSportRequestWatcher(),
    ]);
}

export default rootSportSaga;
