import endpoints from '../../configs/Endpoints.config';
import { getAxiosConfig, getFomatedError } from '../../services/ApiService';

const getAllSports = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${endpoints.baseURL.apiUrl}${endpoints.sport.list}`,
        method: 'GET',
        headers: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });
    return response;
};

const getUserSports = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${
            endpoints.baseURL.apiUrl
        }${endpoints.sport.getUserSports.replace(
            ':USER_SLUG',
            params.user_slug,
        )}`,
        method: 'POST',
        headers: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });
    return response;
};

const addUpdateSport = params => {
    const dataParams = { type: 'upload' };
    const { axiosInstance } = getAxiosConfig(dataParams);
    const response = axiosInstance({
        url: `${endpoints.baseURL.apiUrl}${endpoints.sport.addUpdate}`,
        method: 'POST',
        data: params,
        headers: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return getFomatedError(error);
        });
    return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAllSports,
    getUserSports,
    addUpdateSport,
};
