import React from 'react';

const Footer = () => {
    return (
        <footer id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 footer-logo text-white">
                        24x7exch
                    </div>
                    <div className="col-md-8 footer-right text-white text-md-end">
                        &copy; 2023 All Rights Reserved. 24x7exch.in
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
