import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { isPermission } from '../../utils/AxoisHelper';

const AddUserModal = props => {
    const { isShow, toggleModal, backdrop, loggedUserData, handleActionItem } =
        props;

    return (
        <Modal
            show={isShow}
            onHide={toggleModal}
            backdrop={backdrop || true}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>{loggedUserData?.username || 'User'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <h4>Accounts</h4>
                </div>
                <div className="account-modal-menu">
                    <ul className="ps-0">
                        {isPermission('menuMyMarket') && (
                            <li>
                                <Link to="/my-market">
                                    <span className="link-icon">
                                        <img src="assets/images/icon/stock-market.png" />
                                    </span>
                                    <span className="link-value">
                                        My <br />
                                        Market
                                    </span>
                                </Link>
                            </li>
                        )}
                        {isPermission('menuProfitLoss') && (
                            <li>
                                <Link to="/profit-loss">
                                    <span className="link-icon">
                                        <img src="assets/images/icon/money.png" />
                                    </span>
                                    <span className="link-value">
                                        Profit & <br />
                                        Loss
                                    </span>
                                </Link>
                            </li>
                        )}
                        {isPermission('menuAccStmt') && (
                            <li>
                                <Link to="/account-statement">
                                    <span className="link-icon">
                                        <img src="assets/images/icon/file.png" />
                                    </span>
                                    <span className="link-value">
                                        Account <br />
                                        Statement
                                    </span>
                                </Link>
                            </li>
                        )}
                        {isPermission('menuBetHist') && (
                            <li>
                                <Link to="bet-history">
                                    <span className="link-icon">
                                        <img src="assets/images/icon/bet.png" />
                                    </span>
                                    <span className="link-value">
                                        Bet <br />
                                        History
                                    </span>
                                </Link>
                            </li>
                        )}
                        {isPermission('menuLiveBetHist') && (
                            <li>
                                <Link to="bet-history-live">
                                    <span className="link-icon">
                                        <img src="assets/images/icon/live-betting.png" />
                                    </span>
                                    <span className="link-value">
                                        Live Bet <br />
                                        History
                                    </span>
                                </Link>
                            </li>
                        )}
                        {isPermission('menuIntBetHist') && (
                            <li>
                                <Link to="/bet-history-international">
                                    <span className="link-icon">
                                        <img src="assets/images/icon/casino-chip.png" />
                                    </span>
                                    <span className="link-value">
                                        Internation <br />
                                        Bet History
                                    </span>
                                </Link>
                            </li>
                        )}
                        {isPermission('menuMatchPnL') && (
                            <li>
                                <Link to="/profit-loss-match">
                                    <span className="link-icon">
                                        <img src="assets/images/icon/wallet.png" />
                                    </span>
                                    <span className="link-value">
                                        Match <br />
                                        PnL
                                    </span>
                                </Link>
                            </li>
                        )}
                        {isPermission('menuChangePassword') && (
                            <li>
                                <Link
                                    onClick={() =>
                                        handleActionItem('changePassword')
                                    }>
                                    <span className="link-icon">
                                        <img src="assets/images/icon/padlock.png" />
                                    </span>
                                    <span className="link-value">
                                        Change <br />
                                        Password
                                    </span>
                                </Link>
                            </li>
                        )}
                        {isPermission('menuCassionResult') && (
                            <li>
                                <Link>
                                    <span className="link-icon">
                                        <img src="assets/images/icon/online-betting.png" />
                                    </span>
                                    <span className="link-value">
                                        Cassion <br />
                                        Result
                                    </span>
                                </Link>
                            </li>
                        )}

                        <li>
                            <Link onClick={() => handleActionItem('logout')}>
                                <span className="link-icon">
                                    <img src="assets/images/icon/logout.png" />
                                </span>
                                <span className="link-value">Logout</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddUserModal;
