import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authSelectors } from '../../core/resource/auth';
import { userSelectors } from '../../core/resource/users';
import { isPermission } from '../../utils/AxoisHelper';
import 'boxicons';

const Menubar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginRes = useSelector(state => authSelectors.getLoginRes(state));
    const loggedUserData = loginRes?.data?.data?.user || null;
    const isGetRolesPending = useSelector(state =>
        userSelectors.getIsGetRolesPending(state),
    );
    const rolesRes = useSelector(state => userSelectors.getRolesRes(state));
    const rolesResError = useSelector(state =>
        userSelectors.getRolesResError(state),
    );

    const [rolesList, setRolesList] = useState(null);

    useEffect(() => {
        if (!isGetRolesPending) {
            if (rolesResError) {
                return;
            }
            if (rolesRes?.data?.data && rolesRes?.data?.success) {
                setRolesList(rolesRes.data.data);
            }
        }
    }, [isGetRolesPending, rolesRes, rolesResError]);

    const populateSubMenu = (mName = '') => {
        if (mName === 'roles') {
            if (rolesList?.roles) {
                return rolesList.roles.map((item, key) => {
                    if (item.id > loggedUserData?.role_id) {
                        return (
                            <li
                                key={key}
                                data-roleslug={item.slug}
                                className="nav-item">
                                <Link
                                    className="nav-link"
                                    to={`/users/${item.slug}`}>
                                    {item.role_title}
                                </Link>
                            </li>
                        );
                    }
                    return '';
                });
            }
        }
    };
    const renderOtherMenu = () => {
        if (loggedUserData) {
            return;
        }
        return (
            <>
                <li className="nav-item">
                    <a
                        className="nav-link active"
                        aria-current="page"
                        href="index.html">
                        <span className="icon">
                            <img
                                src="assets/images/icon/svg/play.svg"
                                alt=""
                                className="img-fluid"
                            />
                        </span>
                        Inplay
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="form-page.html">
                        <span className="icon">
                            <img
                                src="assets/images/icon/svg/wicket.svg"
                                alt=""
                                className="img-fluid"
                            />
                        </span>
                        Cricket
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">
                        <span className="icon">
                            <img
                                src="assets/images/icon/svg/football.svg"
                                alt=""
                                className="img-fluid"
                            />
                        </span>
                        Soccer
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">
                        <span className="icon">
                            <img
                                src="assets/images/icon/svg/tennis-racket.svg"
                                alt=""
                                className="img-fluid"
                            />
                        </span>
                        Tennis
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">
                        <span className="icon">
                            <img
                                src="assets/images/icon/svg/casino.svg"
                                alt=""
                                className="img-fluid"
                            />
                        </span>
                        Cassino
                    </a>
                </li>
            </>
        );
    };

    const renderLoggedUserMenu = () => {
        if (!loggedUserData) {
            return;
        }
        return (
            <>
                {isPermission('inPlay') && (
                    <li className="nav-item">
                        <Link className="nav-link" to="/">
                            <span className="icon">
                                <img
                                    src="assets/images/icon/svg/play.svg"
                                    alt=""
                                    className="img-fluid"
                                />
                            </span>
                            InPlay
                        </Link>
                    </li>
                )}
                {isPermission('usersList') && (
                    <li className="nav-item">
                        <Link className="nav-link">
                            <span className="icon">
                                <box-icon
                                    type="regular"
                                    name="user"
                                    size="sx"
                                    color="#fff"></box-icon>
                            </span>
                            Users
                        </Link>
                        <ul className="sub-menu">{populateSubMenu('roles')}</ul>
                    </li>
                )}
                {isPermission('myMarket') && (
                    <li className="nav-item">
                        <Link className="nav-link" to="/my-market">
                            <span className="icon">
                                <box-icon
                                    type="regular"
                                    name="store-alt"
                                    size="sx"
                                    color="#fff"></box-icon>
                            </span>
                            My Market
                        </Link>
                    </li>
                )}
                {isPermission('blockMarket') && (
                    <li className="nav-item">
                        <Link className="nav-link" to="/block-market">
                            <span className="icon">
                                <box-icon
                                    type="regular"
                                    name="store-alt"
                                    size="sx"
                                    color="#fff"></box-icon>
                            </span>
                            Block Market
                        </Link>
                    </li>
                )}
                {isPermission('report') && (
                    <li className="nav-item">
                        <a className="nav-link" href="">
                            <span className="icon">
                                <box-icon
                                    type="regular"
                                    name="note"
                                    size="sx"
                                    color="#fff"></box-icon>
                            </span>
                            Report
                        </a>
                        <ul className="sub-menu">
                            <li className="nav-item">
                                <Link className="nav-link" to="/accountinfo">
                                    Account Info
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/account-statement">
                                    Account Statement
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/client-pl">
                                    Client P/L
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/account-statement">
                                    Market P/L
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/account-statement">
                                    Sport P/L
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/account-statement">
                                    User P/L
                                </Link>
                            </li>
                        </ul>
                    </li>
                )}
            </>
        );
    };
    return (
        <nav className="navbar navbar-expand-lg ">
            <div className="container">
                <div className="logo-text d-md-none">24x7exch</div>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent">
                    <ul className="navbar-nav text-center">
                        {renderOtherMenu()}
                        {/* After Login Menu Item */}
                        {renderLoggedUserMenu()}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Menubar;
