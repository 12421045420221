import { createAction } from 'redux-act';

const handleClearAuthStore = createAction(
    'HANDLE_CLEAR_AUTH_STORE',
    data => data,
);

const loginUserRequest = createAction('LOGIN_USER_REQUEST', data => data);
const loginUserSuccess = createAction('LOGIN_USER_SUCCESS', data => data);
const loginUserError = createAction('LOGIN_USER_ERROR', data => data);

const signupUserRequest = createAction('SIGNUP_USER_REQUEST', data => data);
const signupUserSuccess = createAction('SIGNUP_USER_SUCCESS', data => data);
const signupUserError = createAction('SIGNUP_USER_ERROR');

const logoutUserRequest = createAction('LOGOUT_USER_REQUEST', data => data);
const logoutUserSuccess = createAction('LOGOUT_USER_SUCCESS', data => data);
const logoutUserError = createAction('LOGOUT_USER_ERROR');

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    handleClearAuthStore,

    loginUserRequest,
    loginUserSuccess,
    loginUserError,

    signupUserRequest,
    signupUserSuccess,
    signupUserError,

    logoutUserRequest,
    logoutUserSuccess,
    logoutUserError,
};
