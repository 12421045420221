import { createReducer } from 'redux-act';
import actions from './sportActions';

const initialState = {
    isGetAllSportsPending: false,
    getAllSportsRes: null,
    getAllSportsResError: null,

    isGetUserSportsPending: false,
    getUserSportsRes: null,
    getUserSportsResError: null,

    isAddUpdateSportPending: false,
    addUpdateSportRes: null,
    addUpdateSportResError: null,
};

//
const handleGetAllSportsRequest = state => {
    return {
        ...state,
        ...{
            isGetAllSportsPending: true,
            getAllSportsRes: null,
            getAllSportsResError: null,
        },
    };
};
const handleGetAllSportsSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isGetAllSportsPending: false,
            getAllSportsRes: payload,
            getAllSportsResError: null,
        },
    };
};
const handleGetAllSportsError = (state, payload) => ({
    ...state,
    ...{
        isGetAllSportsPending: false,
        getAllSportsRes: null,
        getAllSportsResError: payload,
    },
});

//
const handleGetUserSportsRequest = state => {
    return {
        ...state,
        ...{
            isGetUserSportsPending: true,
            getUserSportsRes: null,
            getUserSportsResError: null,
        },
    };
};
const handleGetUserSportsSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isGetUserSportsPending: false,
            getUserSportsRes: payload,
            getUserSportsResError: null,
        },
    };
};
const handleGetUserSportsError = (state, payload) => ({
    ...state,
    ...{
        isGetUserSportsPending: false,
        getUserSportsRes: null,
        getUserSportsResError: payload,
    },
});

//
const handleAddUpdateSportRequest = state => {
    return {
        ...state,
        ...{
            isAddUpdateSportPending: true,
            addUpdateSportRes: null,
            addUpdateSportResError: null,
        },
    };
};
const handleAddUpdateSportSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isAddUpdateSportPending: false,
            addUpdateSportRes: payload,
            addUpdateSportResError: null,
        },
    };
};
const handleAddUpdateSportError = (state, payload) => ({
    ...state,
    ...{
        isAddUpdateSportPending: false,
        addUpdateSportRes: null,
        addUpdateSportResError: payload,
    },
});

//
const clearSportStore = (state, payload) => {
    switch (payload) {
        case 'RES':
            return {
                ...state,
                ...{
                    isGetAllSportsPending: false,
                    getAllSportsRes: null,
                    getAllSportsResError: null,
                },
            };
        default:
            return state;
    }
};
export default createReducer(
    {
        [actions.getAllSportsRequest]: handleGetAllSportsRequest,
        [actions.getAllSportsSuccess]: handleGetAllSportsSuccess,
        [actions.getAllSportsError]: handleGetAllSportsError,

        [actions.getUserSportsRequest]: handleGetUserSportsRequest,
        [actions.getUserSportsSuccess]: handleGetUserSportsSuccess,
        [actions.getUserSportsError]: handleGetUserSportsError,

        [actions.addUpdateSportRequest]: handleAddUpdateSportRequest,
        [actions.addUpdateSportSuccess]: handleAddUpdateSportSuccess,
        [actions.addUpdateSportError]: handleAddUpdateSportError,

        [actions.handleClearSportStore]: clearSportStore,
    },
    initialState,
);
