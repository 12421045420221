import { createReducer } from 'redux-act';
import actions from './authActions';

const initialState = {
    isLoginPending: false,
    loginRes: null,
    loginResError: null,

    isSignupPending: false,
    signulRes: null,
    signupResError: null,
};

//
const handleLoginRequest = state => {
    return {
        ...state,
        ...{
            isLoginPending: true,
            loginRes: null,
            loginResError: null,
        },
    };
};
const handleLoginSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isLoginPending: false,
            loginRes: payload,
            loginResError: null,
        },
    };
};
const handleLoginError = (state, payload) => ({
    ...state,
    ...{
        isLoginPending: false,
        loginRes: null,
        loginResError: payload,
    },
});

//
const handleSignupRequest = state => {
    return {
        ...state,
        ...{
            isSignupPending: true,
            signupRes: null,
            signupResError: null,
        },
    };
};
const handleSignupSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isSignupPending: false,
            signupRes: payload,
            signupResError: null,
        },
    };
};
const handleSignupError = (state, payload) => ({
    ...state,
    ...{
        isSignupPending: false,
        signupRes: null,
        signupResError: payload,
    },
});

//
const clearAuthStore = (state, payload) => {
    switch (payload) {
        case 'LOGIN_RES':
            return {
                ...state,
                ...{
                    isLoginPending: false,
                    loginRes: null,
                    loginResError: null,
                },
            };
        case 'SIGNUP_RES':
            return {
                ...state,
                ...{
                    isSignupPending: false,
                    signupRes: null,
                    signupResError: null,
                },
            };
        default:
            return state;
    }
};
export default createReducer(
    {
        [actions.loginUserRequest]: handleLoginRequest,
        [actions.loginUserSuccess]: handleLoginSuccess,
        [actions.loginUserError]: handleLoginError,

        [actions.signupUserRequest]: handleSignupRequest,
        [actions.signupUserSuccess]: handleSignupSuccess,
        [actions.signupUserError]: handleSignupError,

        [actions.handleClearAuthStore]: clearAuthStore,
    },
    initialState,
);
