import React from 'react';
import { Suspense } from 'react';
import { Routes } from 'react-router-dom';
import HomeRouter from './guestRouters/home.route';
import LandingRouter from './guestRouters/landing.route';
// import NotFound from '../pages/NotFound'

function MainRouter() {
    return (
        <div className="App">
            <Suspense
                fallback={
                    <div className="sm:ml-0 pr-5 py-52 row-start-2 row-end-3 col-start-1 md:col-start-2 col-end-3 place-self-center">
                        ...........Loading...........
                    </div>
                }>
                <HomeRouter />
            </Suspense>
            <LandingRouter />
            <Routes>
                {/* <Route path="/dashboard" element={<Courses />}>
                    <Route path="search" element={<Search />} />
                    <Route path="region-wise" element={<List />} />
                </Route> */}
                {/* <Route path="/404" element={<NotFound />} />
                <Route path="/*" element={<Navigate replace to="/404" />} /> */}
            </Routes>
        </div>
    );
}

export default MainRouter;
