// import { all, call, put, select, take } from 'redux-saga/effects';
import {
    take,
    put,
    call,
    all,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import { accountActions as actions, accountService } from './index';
import { notificationActions } from '../notification';

function* handleGetUserBalanceRequest() {
    const action = yield take(actions.getUserBalanceRequest.getType());
    const dataParams = action.payload;
    let res;
    try {
        res = yield call(accountService.getUserBalance, dataParams);
        if (res.isError) {
            yield put(actions.getUserBalanceError(res));
            yield put(
                notificationActions.openSnackbar({
                    text: res.errorMsg || res.errorMessage,
                    alertType: 'danger',
                }),
            );
        } else {
            yield put(actions.getUserBalanceSuccess(res));
        }
    } catch (error) {
        yield put(actions.getUserBalanceError());
    }
}

function* handleGetUserLadgerRequest() {
    const action = yield take(actions.getUserLadgerRequest.getType());
    const dataParams = action.payload;
    let res;
    try {
        res = yield call(accountService.getUserLadger, dataParams);
        if (res.isError) {
            yield put(actions.getUserLadgerError(res));
            yield put(
                notificationActions.openSnackbar({
                    text: res.errorMsg || res.errorMessage,
                    alertType: 'danger',
                }),
            );
        } else {
            yield put(actions.getUserLadgerSuccess(res));
        }
    } catch (error) {
        yield put(actions.getUserLadgerError());
    }
}

function* handleChipsDepositRequest() {
    const action = yield take(actions.chipsDepositRequest.getType());
    const dataParams = action.payload;
    let res;
    try {
        res = yield call(accountService.chipsDeposit, dataParams);
        if (res.isError) {
            yield put(actions.chipsDepositError(res));
            yield put(
                notificationActions.openSnackbar({
                    text: res.errorMsg || res.errorMessage,
                    alertType: 'danger',
                }),
            );
        } else {
            yield put(actions.chipsDepositSuccess(res));
        }
    } catch (error) {
        yield put(actions.chipsDepositError());
    }
}

function* handleChipsWithdrawRequest() {
    const action = yield take(actions.chipsWithdrawRequest.getType());
    const dataParams = action.payload;
    let res;
    try {
        res = yield call(accountService.chipsWithdraw, dataParams);
        if (res.isError) {
            yield put(actions.chipsWithdrawError(res));
            yield put(
                notificationActions.openSnackbar({
                    text: res.errorMsg || res.errorMessage,
                    alertType: 'danger',
                }),
            );
        } else {
            yield put(actions.chipsWithdrawSuccess(res));
        }
    } catch (error) {
        yield put(actions.chipsWithdrawError());
    }
}

function* handleGetUserBalanceRequestWatcher() {
    yield takeEvery(actions.getUserBalanceRequest, handleGetUserBalanceRequest);
}

function* handleGetUserLadgerRequestWatcher() {
    yield takeEvery(actions.getUserLadgerRequest, handleGetUserLadgerRequest);
}

function* handleChipsDepositRequestWatcher() {
    yield takeEvery(actions.chipsDepositRequest, handleChipsDepositRequest);
}

function* handleChipsWithdrawRequestWatcher() {
    yield takeEvery(actions.chipsWithdrawRequest, handleChipsWithdrawRequest);
}

function* rootAccountSaga() {
    yield all([
        handleGetUserBalanceRequest(),
        handleGetUserBalanceRequestWatcher(),

        //handleGetUserLadgerRequest(),
        handleGetUserLadgerRequestWatcher(),

        handleChipsDepositRequest(),
        handleChipsDepositRequestWatcher(),

        handleChipsWithdrawRequest(),
        handleChipsWithdrawRequestWatcher(),
    ]);
}

export default rootAccountSaga;
