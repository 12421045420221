import React from 'react';

const FormPage = () => {
    return (
        <div className="form-area mt-3">
            <div className="container">
                <div className="card">
                    <div className="card-header">
                        <div className="header-w-icon">
                            <span>
                                <img src="assets/images/icon/game.png" alt="" />
                            </span>
                            <h4 className="mb-0">Form</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <form className="row g-3">
                            <div className="col-md-6">
                                <label
                                    htmlFor="inputEmail4"
                                    className="form-label">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    className="form-control required"
                                    id="inputEmail4"
                                />
                                <span className="field-warning">
                                    Field Required
                                </span>
                            </div>
                            <div className="col-md-6">
                                <label
                                    htmlFor="inputPassword4"
                                    className="form-label">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    className="form-control required"
                                    id="inputPassword4"
                                />
                                <span className="field-warning">
                                    Field Required
                                </span>
                            </div>
                            <div className="col-6">
                                <label
                                    htmlFor="inputAddress"
                                    className="form-label">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputAddress"
                                    placeholder="1234 Main St"
                                />
                            </div>
                            <div className="col-6">
                                <label
                                    htmlFor="inputAddress2"
                                    className="form-label">
                                    Address 2
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputAddress2"
                                    placeholder="Apartment, studio, or floor"
                                />
                            </div>
                            <div className="col-md-6">
                                <label
                                    htmlFor="inputCity"
                                    className="form-label">
                                    City
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputCity"
                                />
                            </div>
                            <div className="col-md-4">
                                <label
                                    htmlFor="inputState"
                                    className="form-label">
                                    State
                                </label>
                                <select id="inputState" className="form-select">
                                    <option selected>Choose...</option>
                                    <option>...</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <label
                                    htmlFor="inputZip"
                                    className="form-label">
                                    Zip
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputZip"
                                />
                            </div>
                            <div className="col-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="gridCheck"
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="gridCheck">
                                        Check me out
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                <button
                                    type="submit"
                                    className="btn btn-primary">
                                    Sign in
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormPage;
