import { createReducer } from 'redux-act';
import actions from './userActions';

const initialState = {
    isAddUserPending: false,
    addUserRes: null,
    addUserResError: null,

    isDeleteUserPending: false,
    deleteUserRes: null,
    deleteUserResError: null,

    isGetUsersPending: false,
    getUsersRes: null,
    getUsersResError: null,

    isGetRolesPending: false,
    getRolesRes: null,
    getRolesResError: null,

    isGetSubUsersPending: false,
    getSubUsersRes: null,
    getSubUsersResError: null,

    isGetUserDetailsPending: false,
    getUserDetailsRes: null,
    getUserDetailsResError: null,

    isGetUserProfilePending: false,
    getUserProfileRes: null,
    getUserProfileResError: null,

    isResetPasswordPending: false,
    resetPasswordRes: null,
    resetPasswordResError: null,

    isChangePasswordPending: false,
    changePasswordRes: null,
    changePasswordResError: null,

    isToggleUserBetLockPending: false,
    toggleUserBetLockRes: null,
    toggleUserBetLockResError: null,

    isUpdateUserStatusPending: false,
    updateUserStatusRes: null,
    updateUserStatusResError: null,
};

//
const handleAddUserRequest = state => {
    return {
        ...state,
        ...{
            isAddUserPending: true,
            addUserRes: null,
            addUserResError: null,
        },
    };
};
const handleAddUserSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isAddUserPending: false,
            addUserRes: payload,
            addUserResError: null,
        },
    };
};
const handleAddUserError = (state, payload) => ({
    ...state,
    ...{
        isAddUserPending: false,
        addUserRes: null,
        addUserResError: payload,
    },
});

// delete User
const handleDeleteUserRequest = state => {
    return {
        ...state,
        ...{
            isDeleteUserPending: true,
            deleteUserRes: null,
            deleteUserResError: null,
        },
    };
};
const handleDeleteUserSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isDeleteUserPending: false,
            deleteUserRes: payload,
            deleteUserResError: null,
        },
    };
};
const handleDeleteUserError = (state, payload) => ({
    ...state,
    ...{
        isDeleteUserPending: false,
        deleteUserRes: null,
        deleteUserResError: payload,
    },
});

//
const handleGetUsersRequest = state => {
    return {
        ...state,
        ...{
            isGetUsersPending: true,
            getUsersRes: null,
            getUsersResError: null,
        },
    };
};
const handleGetUsersSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isGetUsersPending: false,
            getUsersRes: payload,
            getUsersResError: null,
        },
    };
};
const handleGetUsersError = (state, payload) => ({
    ...state,
    ...{
        isGetUsersPending: false,
        getUsersRes: null,
        getUsersResError: payload,
    },
});

//
const handleGetRolesRequest = state => {
    return {
        ...state,
        ...{
            isGetRolesPending: true,
            getRolesRes: null,
            getRolesResError: null,
        },
    };
};
const handleGetRolesSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isGetRolesPending: false,
            getRolesRes: payload,
            getRolesResError: null,
        },
    };
};
const handleGetRolesError = (state, payload) => ({
    ...state,
    ...{
        isGetRolesPending: false,
        getRolesRes: null,
        getRolesResError: payload,
    },
});

//
const handleGetSubUsersRequest = state => {
    return {
        ...state,
        ...{
            isGetSubUsersPending: true,
            getSubUsersRes: null,
            getSubUsersResError: null,
        },
    };
};
const handleGetSubUsersSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isGetSubUsersPending: false,
            getSubUsersRes: payload,
            getSubUsersResError: null,
        },
    };
};
const handleGetSubUsersError = (state, payload) => ({
    ...state,
    ...{
        isGetSubUsersPending: false,
        getSubUsersRes: null,
        getSubUsersResError: payload,
    },
});

//
const handleGetUserDetailsRequest = state => {
    return {
        ...state,
        ...{
            isGetUserDetailsPending: true,
            getUserDetailsRes: null,
            getUserDetailsResError: null,
        },
    };
};
const handleGetUserDetailsSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isGetUserDetailsPending: false,
            getUserDetailsRes: payload,
            getUserDetailsResError: null,
        },
    };
};
const handleGetUserDetailsError = (state, payload) => ({
    ...state,
    ...{
        isGetUserDetailsPending: false,
        getUserDetailsRes: null,
        getUserDetailsResError: payload,
    },
});

//
const handleGetUserProfileRequest = state => {
    return {
        ...state,
        ...{
            isGetUserProfilePending: true,
            getUserProfileRes: null,
            getUserProfileResError: null,
        },
    };
};
const handleGetUserProfileSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isGetUserProfilePending: false,
            getUserProfileRes: payload,
            getUserProfileResError: null,
        },
    };
};
const handleGetUserProfileError = (state, payload) => ({
    ...state,
    ...{
        isGetUserProfilePending: false,
        getUserProfileRes: null,
        getUserProfileResError: payload,
    },
});

//
const handleResetPasswordRequest = state => {
    return {
        ...state,
        ...{
            isResetPasswordPending: true,
            resetPasswordRes: null,
            resetPasswordResError: null,
        },
    };
};
const handleResetPasswordSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isResetPasswordPending: false,
            resetPasswordRes: payload,
            resetPasswordResError: null,
        },
    };
};
const handleResetPasswordError = (state, payload) => ({
    ...state,
    ...{
        isResetPasswordPending: false,
        resetPasswordRes: null,
        resetPasswordResError: payload,
    },
});

//
const handleChangePasswordRequest = state => {
    return {
        ...state,
        ...{
            isChangePasswordPending: true,
            changePasswordRes: null,
            changePasswordResError: null,
        },
    };
};
const handleChangePasswordSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isChangePasswordPending: false,
            changePasswordRes: payload,
            changePasswordResError: null,
        },
    };
};
const handleChangePasswordError = (state, payload) => ({
    ...state,
    ...{
        isChangePasswordPending: false,
        changePasswordRes: null,
        changePasswordResError: payload,
    },
});

//
const handleToggleUserBetLockRequest = state => {
    return {
        ...state,
        ...{
            isToggleUserBetLockPending: true,
            toggleUserBetLockRes: null,
            toggleUserBetLockResError: null,
        },
    };
};
const handleToggleUserBetLockSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isToggleUserBetLockPending: false,
            toggleUserBetLockRes: payload,
            toggleUserBetLockResError: null,
        },
    };
};
const handleToggleUserBetLockError = (state, payload) => ({
    ...state,
    ...{
        isToggleUserBetLockPending: false,
        toggleUserBetLockRes: null,
        toggleUserBetLockResError: payload,
    },
});

//
const handleUpdateUserStatusRequest = state => {
    return {
        ...state,
        ...{
            isUpdateUserStatusPending: true,
            updateUserStatusRes: null,
            updateUserStatusResError: null,
        },
    };
};
const handleUpdateUserStatusSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isUpdateUserStatusPending: false,
            updateUserStatusRes: payload,
            updateUserStatusResError: null,
        },
    };
};
const handleUpdateUserStatusError = (state, payload) => ({
    ...state,
    ...{
        isUpdateUserStatusPending: false,
        updateUserStatusRes: null,
        updateUserStatusResError: payload,
    },
});

//
const clearUserStore = (state, payload) => {
    switch (payload) {
        case 'DELETE_USER_RES':
            return {
                ...state,
                ...{
                    isDeleteUserPending: false,
                    deleteUserRes: null,
                    deleteUserResError: null,
                },
            };
        case 'ADD_USER_RES':
            return {
                ...state,
                ...{
                    isAddUserPending: false,
                    addUserRes: null,
                    addUserResError: null,
                },
            };
        case 'CHANGE_PASSWORD_RES':
            return {
                ...state,
                ...{
                    isChangePasswordPending: false,
                    changePasswordRes: null,
                    changePasswordResError: null,
                },
            };
        default:
            return state;
    }
};
export default createReducer(
    {
        [actions.addUserRequest]: handleAddUserRequest,
        [actions.addUserSuccess]: handleAddUserSuccess,
        [actions.addUserError]: handleAddUserError,

        [actions.deleteUserRequest]: handleDeleteUserRequest,
        [actions.deleteUserSuccess]: handleDeleteUserSuccess,
        [actions.deleteUserError]: handleDeleteUserError,

        [actions.getUsersRequest]: handleGetUsersRequest,
        [actions.getUsersSuccess]: handleGetUsersSuccess,
        [actions.getUsersError]: handleGetUsersError,

        [actions.getRolesRequest]: handleGetRolesRequest,
        [actions.getRolesSuccess]: handleGetRolesSuccess,
        [actions.getRolesError]: handleGetRolesError,

        [actions.getSubUsersRequest]: handleGetSubUsersRequest,
        [actions.getSubUsersSuccess]: handleGetSubUsersSuccess,
        [actions.getSubUsersError]: handleGetSubUsersError,

        [actions.getUserDetailsRequest]: handleGetUserDetailsRequest,
        [actions.getUserDetailsSuccess]: handleGetUserDetailsSuccess,
        [actions.getUserDetailsError]: handleGetUserDetailsError,

        [actions.getUserProfileRequest]: handleGetUserProfileRequest,
        [actions.getUserProfileSuccess]: handleGetUserProfileSuccess,
        [actions.getUserProfileError]: handleGetUserProfileError,

        [actions.resetPasswordRequest]: handleResetPasswordRequest,
        [actions.resetPasswordSuccess]: handleResetPasswordSuccess,
        [actions.resetPasswordError]: handleResetPasswordError,

        [actions.changePasswordRequest]: handleToggleUserBetLockRequest,
        [actions.changePasswordSuccess]: handleToggleUserBetLockSuccess,
        [actions.changePasswordError]: handleToggleUserBetLockError,

        [actions.updateUserStatusRequest]: handleUpdateUserStatusRequest,
        [actions.updateUserStatusSuccess]: handleUpdateUserStatusSuccess,
        [actions.updateUserStatusError]: handleUpdateUserStatusError,

        [actions.handleClearUserStore]: clearUserStore,
    },
    initialState,
);
