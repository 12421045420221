import React from 'react';
import Header from './sections/header';
import Footer from './sections/footer';
import Table from 'react-bootstrap/Table';

function Home() {
    return (
        <>
            <Header />
            <main className="page-body-area">
                <section className="banner">
                    <img
                        src="assets/images/cricket_2.jpg"
                        alt=""
                        className="img-fluid"
                    />
                </section>

                <section className="py-4">
                    <div className="container">
                        <Table
                            className="table"
                            responsive
                            bordered
                            hover
                            striped>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Table heading</th>
                                    <th>Table heading</th>
                                    <th>Table heading</th>
                                    <th>Table heading</th>
                                    <th>Table heading</th>
                                    <th>Table heading</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                    <td>Table cell</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default Home;
