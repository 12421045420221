import { createAction } from 'redux-act';

const handleClearUserStore = createAction(
    'HANDLE_CLEAR_USER_STORE',
    data => data,
);

const addUserRequest = createAction('ADD_USER_REQUEST', data => data);
const addUserSuccess = createAction('ADD_USER_SUCCESS', data => data);
const addUserError = createAction('ADD_USER_ERROR', data => data);

const deleteUserRequest = createAction('DELETE_USER_REQUEST', data => data);
const deleteUserSuccess = createAction('DELETE_USER_SUCCESS', data => data);
const deleteUserError = createAction('DELETE_USER_ERROR');

const getUsersRequest = createAction('GET_USERS_REQUEST', data => data);
const getUsersSuccess = createAction('GET_USERS_SUCCESS', data => data);
const getUsersError = createAction('GET_USERS_ERROR');

const getRolesRequest = createAction('GET_ROLES_REQUEST', data => data);
const getRolesSuccess = createAction('GET_ROLES_SUCCESS', data => data);
const getRolesError = createAction('GET_ROLES_ERROR');

const getSubUsersRequest = createAction('GET_SUB_USERS_REQUEST', data => data);
const getSubUsersSuccess = createAction('GET_SUB_USERS_SUCCESS', data => data);
const getSubUsersError = createAction('GET_SUB_USERS_ERROR');

const getUserDetailsRequest = createAction(
    'GET_USER_DETAILS_REQUEST',
    data => data,
);
const getUserDetailsSuccess = createAction(
    'GET_USER_DETAILS_SUCCESS',
    data => data,
);
const getUserDetailsError = createAction('GET_USER_DETAILS_ERROR');

const getUserProfileRequest = createAction(
    'GET_USER_PROFILE_REQUEST',
    data => data,
);
const getUserProfileSuccess = createAction(
    'GET_USER_PROFILE_SUCCESS',
    data => data,
);
const getUserProfileError = createAction('GET_USER_PROFILE_ERROR');

const resetPasswordRequest = createAction(
    'RESET_PASSWORD_REQUEST',
    data => data,
);
const resetPasswordSuccess = createAction(
    'RESET_PASSWORD_SUCCESS',
    data => data,
);
const resetPasswordError = createAction('RESET_PASSWORD_ERROR');

const changePasswordRequest = createAction(
    'CHANGE_PASSWORD_REQUEST',
    data => data,
);
const changePasswordSuccess = createAction(
    'CHANGE_PASSWORD_SUCCESS',
    data => data,
);
const changePasswordError = createAction('CHANGE_PASSWORD_ERROR');

const toggleUserBetLockRequest = createAction(
    'TOGGLE_USER_BETLOCK_REQUEST',
    data => data,
);
const toggleUserBetLockSuccess = createAction(
    'TOGGLE_USER_BETLOCK_SUCCESS',
    data => data,
);
const toggleUserBetLockError = createAction('TOGGLE_USER_BETLOCK_ERROR');

const updateUserStatusRequest = createAction(
    'UPDATE_USER_STATUS_REQUEST',
    data => data,
);
const updateUserStatusSuccess = createAction(
    'UPDATE_USER_STATUS_SUCCESS',
    data => data,
);
const updateUserStatusError = createAction('UPDATE_USER_STATUS_ERROR');

export default {
    handleClearUserStore,

    addUserRequest,
    addUserSuccess,
    addUserError,

    deleteUserRequest,
    deleteUserSuccess,
    deleteUserError,

    getUsersRequest,
    getUsersSuccess,
    getUsersError,

    getRolesRequest,
    getRolesSuccess,
    getRolesError,

    getSubUsersRequest,
    getSubUsersSuccess,
    getSubUsersError,

    getUserDetailsRequest,
    getUserDetailsSuccess,
    getUserDetailsError,

    getUserProfileRequest,
    getUserProfileSuccess,
    getUserProfileError,

    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordError,

    changePasswordRequest,
    changePasswordSuccess,
    changePasswordError,

    toggleUserBetLockRequest,
    toggleUserBetLockSuccess,
    toggleUserBetLockError,

    updateUserStatusRequest,
    updateUserStatusSuccess,
    updateUserStatusError,
};
