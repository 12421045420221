// import { all, call, put, select, take } from 'redux-saga/effects';
import { take, put, call, all, takeEvery } from 'redux-saga/effects';
import { authActions as actions, authService } from './index';
import { notificationActions } from '../notification';

function* handleLoginUserRequest() {
    const action = yield take(actions.loginUserRequest.getType());
    const dataParams = action.payload;

    let res;
    try {
        res = yield call(authService.loginUser, dataParams);
        if (res.isError) {
            yield put(actions.loginUserError(res));
            yield put(
                notificationActions.openSnackbar({
                    text: res.errorMsg || res.errorMessage,
                    alertType: 'danger',
                }),
            );
        } else {
            yield put(actions.loginUserSuccess(res));
        }
    } catch (error) {
        yield put(actions.loginUserError());
    }
}

function* handleSignupUserRequest() {
    const action = yield take(actions.signupUserRequest.getType());
    const dataParams = action.payload;

    let res;
    try {
        res = yield call(authService.signupUser, dataParams);
        if (res.isError) {
            yield put(actions.signupUserError(res));
        } else {
            yield put(actions.signupUserSuccess(res));
            console.log('--handlesignupUserRequest--', { dataParams, res });
        }
    } catch (error) {
        yield put(actions.signupUserError());
    }
}

function* handleLoginUserRequestWatcher() {
    yield takeEvery(actions.loginUserRequest, handleLoginUserRequest);
}

function* handleSignupUserRequestWatcher() {
    yield takeEvery(actions.signupUserRequest, handleSignupUserRequest);
}

function* rootAuthSaga() {
    yield all([
        handleLoginUserRequest(),
        handleLoginUserRequestWatcher(),
        handleSignupUserRequest(),
        handleSignupUserRequestWatcher(),
    ]);
}

export default rootAuthSaga;
