import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { accountActions } from '../../core/resource/account';
import { AppWrapper } from '../layouts';
import {
    DatatableWrapper,
    Filter,
    TableBody,
    TableHeader,
} from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Ledger = () => {
    const dispatch = useDispatch();
    const routeParams = useParams();

    const { isGetUserLadgerPending, getUserLadgerRes, getUserLadgerResError } =
        useSelector(state => state.account);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [ladgerList, setLedgerList] = useState([]);
    const [totalLedger, setTotalLedger] = useState(0);

    useEffect(() => {
        getLedger();
    }, []);

    useEffect(() => {
        getLedger();
    }, [startDate, endDate]);

    useEffect(() => {
        if (!isGetUserLadgerPending) {
            if (getUserLadgerResError) {
                return;
            }
            if (
                getUserLadgerRes?.data?.data &&
                getUserLadgerRes?.data?.success
            ) {
                setLedgerList(getUserLadgerRes.data.data?.ledger || []);
                setTotalLedger(getUserLadgerRes.data.data?.total_count || 0);
            }
        }
    }, [isGetUserLadgerPending, getUserLadgerRes, getUserLadgerResError]);

    const getLedger = () => {
        dispatch(
            accountActions.getUserLadgerRequest({
                page: 1,
                show: 100,
                user_slug: 'admin',
            }),
        );
    };

    const headers = [
        {
            isFilterable: false,
            isSortable: false,
            prop: 'sno',
            title: 'S.No.',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'date',
            title: 'Date',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'description',
            title: 'Description',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'credit',
            title: 'Credit',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'debit',
            title: 'Debit',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'commission',
            title: 'Commission',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'balance',
            title: 'Balance',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'remark',
            title: 'remarks',
        },
    ];

    return (
        <AppWrapper>
            <div className="container py-5">
                <DatatableWrapper
                    className="table"
                    body={ladgerList}
                    checkboxProps={{
                        onCheckboxChange: function noRefCheck() {},
                    }}
                    headers={headers}
                    sortProps={{
                        sortValueObj: {
                            date: function noRefCheck() {},
                        },
                    }}>
                    <Row className="mb-4">
                        <Col
                            className="d-flex flex-col justify-content-end align-items-end"
                            lg={4}
                            xs={12}>
                            <Filter />
                        </Col>
                    </Row>
                    <Table responsive bordered hover striped>
                        <TableHeader />
                        <TableBody>
                            {ladgerList.map((item, rowIdx) => {
                                return (
                                    <tr key={rowIdx}>
                                        <td>{rowIdx + 1}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                );
                            })}
                            {ladgerList?.length === 0 && (
                                <tr>
                                    <td colSpan={headers?.length || 0}>
                                        No Records found
                                    </td>
                                </tr>
                            )}
                        </TableBody>
                    </Table>
                </DatatableWrapper>
            </div>
        </AppWrapper>
    );
};

export default Ledger;
