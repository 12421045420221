import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authSelectors, authActions } from '../../core/resource/auth';
import Login from '../../pages/auth/Login';
import Register from '../../pages/auth/Register';
import FromIndex from '../../pages/form';
import Index from '../../pages/index';
import Home2 from '../../pages/home2';
import {
    UsersList,
    MyMarket,
    BlockMarket,
    Ladger,
    AnnouncementList,
    AccountInfo,
    ClientPL,
    MatchLive,
    ProfitLoss,
    ProfitLossMatch,
    BetHistory,
    BetHistoryLive,
    BetHistoryInternational,
} from '../../pages/components';
import AccountStatement from '../../pages/components/AccountStatement';
import { isPermission } from '../../utils/AxoisHelper';

export default function HomeRouter() {
    const dispatch = useDispatch();
    const loginRes = useSelector(state => authSelectors.getLoginRes(state));

    const ProtectRoute = ({ element }) => {
        const authenticatedData = loginRes?.data?.data?.user || null;

        if (!authenticatedData) {
            // Redirect to login if not authenticated
            dispatch(authActions.handleClearAuthStore('LOGIN_RES'));
            window.location.href = '/login';
            return null;
        }
        return element;
    };
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Register />} />
            <Route path="/form" element={<FromIndex />} />
            <Route path="/dashboard" element={<Index />} />
            <Route path="/home" element={<Home2 />} />
            <Route
                path="/users"
                element={<ProtectRoute element={<UsersList />} />}
            />
            <Route
                path="/users/:role"
                element={<ProtectRoute element={<UsersList />} />}
            />
            <Route
                path="/users/:role/:parent_id"
                element={<ProtectRoute element={<UsersList />} />}
            />
            <Route
                path="/my-market"
                element={<ProtectRoute element={<MyMarket />} />}
            />
            <Route
                path="/block-market"
                element={<ProtectRoute element={<BlockMarket />} />}
            />
            <Route
                path="/ladger"
                element={<ProtectRoute element={<Ladger />} />}
            />
            <Route
                path="/announcements"
                element={<ProtectRoute element={<AnnouncementList />} />}
            />
            <Route
                path="/accountinfo"
                element={<ProtectRoute element={<AccountInfo />} />}
            />
            <Route
                path="/account-statement"
                element={<ProtectRoute element={<AccountStatement />} />}
            />
            <Route
                path="/client-pl"
                element={<ProtectRoute element={<ClientPL />} />}
            />
            <Route
                path="/match-live"
                element={<ProtectRoute element={<MatchLive />} />}
            />
            <Route
                path="/profit-loss"
                element={<ProtectRoute element={<ProfitLoss />} />}
            />
            <Route
                path="/profit-loss-match"
                element={<ProtectRoute element={<ProfitLossMatch />} />}
            />
            <Route
                path="/bet-history"
                element={<ProtectRoute element={<BetHistory />} />}
            />
            <Route
                path="/bet-history-live"
                element={<ProtectRoute element={<BetHistoryLive />} />}
            />
            <Route
                path="/bet-history-international"
                element={<ProtectRoute element={<BetHistoryInternational />} />}
            />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}
