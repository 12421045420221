const getIsAddUserPending = state => state.user.isAddUserPending;
const getAddUserRes = state => state.user.addUserRes;
const getAddUserError = state => state.user.addUserResError;

const getIsDeleteUserPending = state => state.user.isDeleteUserPending;
const getDeleteUserRes = state => state.user.deleteUserRes;
const getDeleteUserError = state => state.user.deleteUserResError;

const getIsGetUsersPending = state => state.user.isGetUsersPending;
const getUsersRes = state => state.user.getUsersRes;
const getUsersResError = state => state.user.getUsersResError;

const getIsGetRolesPending = state => state.user.isGetRolesPending;
const getRolesRes = state => state.user.getRolesRes;
const getRolesResError = state => state.user.getRolesResError;

export default {
    getIsAddUserPending,
    getAddUserRes,
    getAddUserError,

    getIsDeleteUserPending,
    getDeleteUserRes,
    getDeleteUserError,

    getIsGetUsersPending,
    getUsersRes,
    getUsersResError,

    getIsGetRolesPending,
    getRolesRes,
    getRolesResError,
};
