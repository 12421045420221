import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const CustButton = props => {
    const {
        onClick,
        text,
        isLoader,
        isDisabled,
        variant,
        className,
        otherProps,
    } = props;
    const btnProps = otherProps || {};
    return (
        <Button
            className={className || ''}
            variant={variant || 'primary'}
            disabled={isDisabled || false}
            onClick={
                onClick ||
                (() => {
                    console.log('None');
                })
            }
            {...btnProps}>
            {isLoader && (
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            )}
            <span className="">{text || 'Submit'}</span>
        </Button>
    );
};

export default CustButton;
