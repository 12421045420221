const getIsLoginPending = state => state.auth.isLoginPending;
const getLoginRes = state => state.auth.loginRes;
const getLoginError = state => state.auth.loginResError;

const getIsSignupPending = state => state.auth.isSignupPending;
const getSignupRes = state => state.auth.signupRes;
const getSignupError = state => state.auth.signupResError;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getIsLoginPending,
    getLoginRes,
    getLoginError,

    getIsSignupPending,
    getSignupRes,
    getSignupError,
};
