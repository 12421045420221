import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../core/resource/users';
import { accountActions } from '../../core/resource/account';
import { AppWrapper } from '../layouts';
import { CustBadge, CustButton } from '../reusable';
import { AddUserModal, ChipsDepositWithdrawModal } from '../modals';
import { defaultPerPageRecords } from '../../core/configs/Constants.config';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader,
} from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {
    getTableSNum,
    getUserCurentRole,
    getRoleInfoById,
} from '../../utils/AppHelper';
import { getUserBalBySlug } from '../../utils/AxoisHelper';

const UsersList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const routeParams = useParams();

    const loggedUserData = useSelector(
        state => state.auth?.loginRes?.data?.data?.user || null,
    );
    const rolesList = useSelector(
        state => state.user?.getRolesRes?.data?.data?.roles || null,
    );
    const {
        isAddUserPending,
        addUserRes,
        addUserResError,
        isGetUsersPending,
        getUsersRes,
        getUsersResError,
    } = useSelector(state => state.user);
    const {
        isChipsDepositPending,
        chipsDepositRes,
        chipsDepositResError,
        isChipsWithdrawPending,
        chipsWithdrawRes,
        chipsWithdrawResError,
    } = useSelector(state => state.account);

    const [selectedUsersArr, setSelectedUsersArr] = useState([]);
    const [selectedUserAction, setSelectedUserAction] = useState('');
    const [loggedUserNextRoleSlug, setLoggedUserNextRoleSlug] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedUserRow, setSelectedUserRow] = useState(null);
    const [addUserParentId, setAddUserParentId] = useState(
        loggedUserData?.id || 0,
    );
    const [addUserRoleId, setAddUserRoleId] = useState(0);
    const [usersList, setUsersList] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [isShowAddUserModal, setIsShowAddUserModal] = useState(false);
    const [
        isShowChipsDepositWithdrawModal,
        setIsShowChipsDepositWithdrawModal,
    ] = useState(false);
    const [chipsType, setChipsType] = useState('');
    const [page, setPage] = useState(1);
    const [perPageRecords, setPerPageRecords] = useState(defaultPerPageRecords);
    const [parentChipsBalance, setParentChipsBalance] = useState(0);
    const [countsClickRoles, setCountsClickRoles] = useState(0);

    useEffect(() => {
        getUsers();
        const roleData = getRoleInfoById(
            loggedUserData?.role_id + 1,
            rolesList,
        );
        console.log({ roleData });
        setLoggedUserNextRoleSlug(roleData?.slug || '');
        setSelectedRole(roleData?.slug || '');
    }, []);

    useEffect(() => {
        if (routeParams?.role) {
            setPage(1);
            setPerPageRecords(defaultPerPageRecords);
            setSelectedRole(routeParams.role);
            setAddUserParentId(loggedUserData.id);
            if (!routeParams?.parent_id)
                setCountsClickRoles(countsClickRoles + 1);
        } else {
            setPage(1);
            setPerPageRecords(defaultPerPageRecords);
            setSelectedRole(loggedUserNextRoleSlug);
        }
        if (routeParams?.parent_id) {
            setAddUserParentId(routeParams.parent_id);
        }
    }, [routeParams]);

    useEffect(() => {
        getUsers();
    }, [countsClickRoles]);

    useEffect(() => {
        getUsers();
    }, [page, perPageRecords]);

    useEffect(() => {
        if (!isGetUsersPending) {
            if (getUsersResError) {
                return;
            }
            if (getUsersRes?.data?.data && getUsersRes?.data?.success) {
                setTotalUsers(getUsersRes.data.data?.total_count || 0);
                setUsersList(getUsersRes.data.data?.users || []);
            }
        }
    }, [isGetUsersPending, getUsersRes, getUsersResError]);

    useEffect(() => {
        if (!isAddUserPending) {
            if (addUserResError) {
                return;
            }
            if (addUserRes?.data?.data && addUserRes?.data?.success) {
                getUsers();
                toggleAddUserModal();
                dispatch(userActions.handleClearUserStore('ADD_USER_RES'));
            }
        }
    }, [isAddUserPending, addUserRes, addUserResError]);

    useEffect(() => {
        if (!isChipsDepositPending) {
            if (chipsDepositResError) {
                return;
            }
            if (chipsDepositRes?.data?.data && chipsDepositRes?.data?.success) {
                getUserBalance();
                toggleChipsDepositWithdrawModal();
                dispatch(
                    accountActions.handleClearAccountStore('CHIPS_DEPOSIT_RES'),
                );
            }
        }
    }, [isChipsDepositPending, chipsDepositRes, chipsDepositResError]);

    useEffect(() => {
        if (!isChipsWithdrawPending) {
            if (chipsWithdrawResError) {
                return;
            }
            if (
                chipsWithdrawRes?.data?.data &&
                chipsWithdrawRes?.data?.success
            ) {
                getUserBalance();
                toggleChipsDepositWithdrawModal();
                dispatch(
                    accountActions.handleClearAccountStore(
                        'CHIPS_WITHDRAW_RES',
                    ),
                );
            }
        }
    }, [isChipsWithdrawPending, chipsWithdrawRes, chipsWithdrawResError]);

    const getUserBalance = () => {
        dispatch(
            accountActions.getUserBalanceRequest({
                user_slug: loggedUserData?.username || '',
            }),
        );
    };

    const getUsers = () => {
        dispatch(
            userActions.getUsersRequest({
                page,
                show: perPageRecords,
                role: selectedRole,
            }),
        );
    };

    const getChildUsers = data => {
        dispatch(
            userActions.getUsersRequest({
                page,
                show: perPageRecords,
                username: data.username,
            }),
        );
    };

    const getMaxPage = () => {
        const mNum = Math.ceil(totalUsers / perPageRecords);
        return mNum;
    };

    const handleOnPageChange = pNum => {
        console.log({ pNum });
        setPage(pNum);
    };

    const handleOnPerPageChange = value => {
        setPerPageRecords(value);
    };

    const toggleChipsModal = async (value, userRow) => {
        setSelectedUserRow(userRow);
        setChipsType(value);

        toggleChipsDepositWithdrawModal();

        // get user balance
        const resData = await getUserBalBySlug({
            // user_slug: userRow?.username || '',
            user_slug: loggedUserData?.username || '',
        });
        setParentChipsBalance(resData?.data?.data?.exposure || 0);
    };

    const toggleChipsDepositWithdrawModal = () => {
        setIsShowChipsDepositWithdrawModal(!isShowChipsDepositWithdrawModal);
    };

    const toggleAddUserModal = e => {
        const parentId = e?.currentTarget?.dataset?.parentid || 0;
        setAddUserParentId(parentId);

        let roleid = e?.currentTarget?.dataset?.roleid || 0;
        roleid = roleid ? parseInt(roleid) + 1 : roleid; // +1 role id for create subuser
        setAddUserRoleId(roleid);
        setIsShowAddUserModal(!isShowAddUserModal);

        console.log({ roleid, parentId });
    };

    const handleOnAddUser = params => {
        dispatch(userActions.addUserRequest(params));
    };

    const handleChipsDepositWithdraw = params => {
        if (chipsType === 'd') {
            dispatch(accountActions.chipsDepositRequest(params));
        } else {
            dispatch(accountActions.chipsWithdrawRequest(params));
        }
    };

    const handleOnSelectUser = (e, userRow) => {
        if (e.target.checked) {
            selectedUsersArr.push(userRow.id);
        } else {
            const index = selectedUsersArr.indexOf(userRow.id);
            selectedUsersArr.splice(index, 1);
        }
        setSelectedUsersArr(selectedUsersArr);
    };

    const handleUpdateUserStatus = () => {
        if (selectedUserAction && selectedUsersArr?.length) {
            const params = {
                id: selectedUsersArr,
                status: selectedUserAction,
            };
            console.log({ params });
            dispatch(userActions.updateUserStatusRequest(params));
        }
    };

    const handleOnChange = e => {
        const { name, value } = e.target;
        if (name === 'user-action') {
            setSelectedUserAction(value);
            console.log({ name, value });
        }
    };

    const handleActionMenu = (action, data) => {
        console.log({ action });
        switch (action) {
            case 'addUser':
                // toggleAddUserModal();
                break;
            case 'changePassword':
                toggleAddUserModal();
                break;
            case 'profitLoss':
                break;
            case 'chipDeposit':
                toggleChipsModal('d', data);
                break;
            case 'chipWithdraw':
                toggleChipsModal('w', data);
                break;
            case 'statement':
                break;
            case 'getChilds':
                console.log({ data });
                navigate(`/users/${selectedRole}/${data?.id || 0}`);
                setAddUserParentId(data?.id || 0);
                getChildUsers(data);
                break;
            default:
                //navigate(`/${action}`);
                break;
        }
    };

    const headers = [
        {
            isFilterable: false,
            isSortable: false,
            prop: 'sno',
            title: 'S.No.',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: '',
            title: '',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'id',
            title: 'User ID',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'source',
            title: 'Website',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'parent',
            title: 'Parent',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'credit_limit',
            title: 'Credit Limit',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'credit_given',
            title: 'Credit Given',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'balance',
            title: 'Balance',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'acc_lock',
            title: 'Acc. Lock',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'bet_lock',
            title: 'Bet Lock',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'm_comm',
            title: 'M.comm',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 's_comm',
            title: 'S.comm',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'depo_with',
            title: 'Depo. / With.',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'viewmore',
            title: 'View More',
        },
    ];
    console.log(
        loggedUserData?.id === addUserParentId,
        loggedUserData?.id,
        addUserParentId,
    );
    return (
        <AppWrapper>
            <div className="container py-5">
                <h3 className="mb-0 mt-1 me-4">All Users</h3>
                <div className="custom-table-filter mb-3">
                    <Row className="">
                        <div className="d-flex flex-col align-items-center mb-2 mb-sm-0 col-md-6 col-sm-12">
                            <div className="table-filterlist d-flex align-items-start me-2">
                                <Form.Select
                                    size="sm"
                                    className="me-2"
                                    name="user-action"
                                    onChange={handleOnChange}>
                                    <option value="">Select Action</option>
                                    <option value="lock_betting">
                                        Lock Betting
                                    </option>
                                    <option value="open_betting">
                                        Open Betting
                                    </option>
                                    <option value="lock_use">Lock User</option>
                                    <option value="unlock_user">
                                        Unlock User
                                    </option>
                                    <option value="close_user">
                                        Close User Account
                                    </option>
                                </Form.Select>
                                <CustButton
                                    text={'Action'}
                                    className="filter-action-btn"
                                    onClick={handleUpdateUserStatus}
                                />
                            </div>
                            {loggedUserNextRoleSlug === selectedRole &&
                                loggedUserData?.id === addUserParentId && (
                                    <CustButton
                                        onClick={toggleAddUserModal}
                                        className="add-btn"
                                        text={'Add User'}
                                        otherProps={{
                                            'data-roleid':
                                                loggedUserData?.role_id,
                                            'data-parentid': loggedUserData?.id,
                                        }}
                                    />
                                )}
                        </div>
                        <div className="col-md-6 col-sm-12 filter-searchbox">
                            <div className="search-block float-md-end">
                                <Form>
                                    <InputGroup size="sm">
                                        <Form.Control placeholder="Search here" />
                                        <Button
                                            type="submit"
                                            variant="outline-secondary">
                                            <box-icon
                                                type="regular"
                                                name="search"
                                                size="sx"
                                                color="#41444a"></box-icon>
                                        </Button>
                                    </InputGroup>
                                </Form>
                            </div>
                        </div>
                    </Row>
                </div>
                <DatatableWrapper
                    className="table"
                    body={usersList}
                    checkboxProps={{
                        onCheckboxChange: function noRefCheck() {},
                    }}
                    headers={headers}
                    sortProps={{
                        sortValueObj: {
                            date: function noRefCheck() {},
                        },
                    }}>
                    {/* <Row className="mb-4">
                        <Col
                            className="d-flex flex-col justify-content-end align-items-end"
                            lg={4}
                            xs={12}>
                            <Filter />
                        </Col>
                    </Row> */}
                    <Table responsive bordered hover>
                        <TableHeader />
                        <TableBody>
                            {usersList.map((item, rowIdx) => {
                                const row = {
                                    ...item,
                                    bet_lock: item.is_betlock ? 'Yes' : 'No',
                                    acc_lock: item.is_locked ? 'Yes' : 'No',
                                    m_comm: item.commission
                                        ? item.commission
                                        : '0.00',
                                    s_comm: item.commission
                                        ? item.commission
                                        : '0.00',
                                    credit_limit: '0.00',
                                    credit_given: '0.00',
                                    balance: '0.00',
                                };
                                return (
                                    <tr key={rowIdx}>
                                        <td>
                                            {getTableSNum(
                                                perPageRecords,
                                                page,
                                                rowIdx,
                                            )}
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="gridCheck"
                                                    onChange={e =>
                                                        handleOnSelectUser(
                                                            e,
                                                            row,
                                                        )
                                                    }
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <a
                                                href="#"
                                                onClick={() =>
                                                    handleActionMenu(
                                                        'getChilds',
                                                        row,
                                                    )
                                                }>
                                                {row.username}
                                            </a>
                                        </td>
                                        <td>{row.source}</td>
                                        <td>{row.parent?.name || ''}</td>
                                        <td>{row.credit_limit}</td>
                                        <td>{row.credit_given}</td>
                                        <td>{row.balance}</td>
                                        <td>{row.acc_lock}</td>
                                        <td>{row.bet_lock}</td>
                                        <td>{row.m_comm}</td>
                                        <td>{row.s_comm}</td>
                                        <td>
                                            <CustBadge
                                                text={'D'}
                                                bg="success"
                                                onClick={() =>
                                                    toggleChipsModal('d', row)
                                                }
                                            />
                                            &nbsp;
                                            <CustBadge
                                                text={'W'}
                                                bg="danger"
                                                onClick={() =>
                                                    toggleChipsModal('w', row)
                                                }
                                            />
                                        </td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    id="dropdown-view"
                                                    className="action-dropdown">
                                                    <span className="user-txt">
                                                        View
                                                    </span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="action-menu">
                                                    <Dropdown.Item
                                                        onClick={
                                                            toggleAddUserModal
                                                        }
                                                        data-roleid={
                                                            row?.role_id
                                                        }
                                                        data-parentid={row?.id}>
                                                        Add User
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            handleActionMenu(
                                                                'statement',
                                                                row,
                                                            )
                                                        }>
                                                        Statement
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            handleActionMenu(
                                                                'profitLoss',
                                                                row,
                                                            )
                                                        }>
                                                        Profit Loss
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            handleActionMenu(
                                                                'changePassword',
                                                                row,
                                                            )
                                                        }>
                                                        Change Password
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            handleActionMenu(
                                                                'chipDeposit',
                                                                row,
                                                            )
                                                        }>
                                                        Chip Deposit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            handleActionMenu(
                                                                'chipWithdraw',
                                                                row,
                                                            )
                                                        }>
                                                        Chip Withdrawal
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <div className="table-pagination">
                        <Row className="mb-4">
                            <Col
                                className="d-flex flex-col align-items-center mb-2 mb-sm-0"
                                lg={3}
                                sm={3}
                                xs={12}>
                                <PaginationOptions
                                    alwaysShowPagination
                                    controlledProps={{
                                        rowsPerPage: perPageRecords,
                                        /** Number of rows shown per page options. */
                                        rowsPerPageOptions: [5, 10, 15, 20],
                                        // /**
                                        //  * The filtered data length. When not using filter control,
                                        //  * then this should equal to the table body's length.
                                        //  */
                                        filteredDataLength: perPageRecords,
                                        /** The function fired when any of the pagination option is changed. */
                                        onRowsPerPageChange: value =>
                                            handleOnPerPageChange(value),
                                    }}
                                />
                            </Col>
                            <Col
                                className="d-flex flex-col justify-content-end align-items-end"
                                lg={9}
                                sm={9}
                                xs={12}>
                                <Pagination
                                    alwaysShowPagination
                                    paginationRange={2}
                                    controlledProps={{
                                        currentPage: page,
                                        maxPage: getMaxPage(),
                                        onPaginationChange: pNum =>
                                            handleOnPageChange(pNum),
                                    }}
                                />
                            </Col>
                            <Col className="mt-2" xs={12}>
                                {/* <BulkCheckboxControl /> */}
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col xs={12}>{/* <StrayResetSelectionButton /> */}</Col>
                    </Row>
                </DatatableWrapper>
            </div>

            <AddUserModal
                isShow={isShowAddUserModal}
                backdrop="static"
                toggleAddUserModal={toggleAddUserModal}
                handleOnAddUser={handleOnAddUser}
                roleId={addUserRoleId}
                parentId={addUserParentId}
            />
            <ChipsDepositWithdrawModal
                isShow={isShowChipsDepositWithdrawModal}
                backdrop="static"
                toggleModal={toggleChipsDepositWithdrawModal}
                handleOnSubmit={handleChipsDepositWithdraw}
                chipType={chipsType}
                selectedUserData={selectedUserRow}
                parentChipsBalance={parentChipsBalance}
            />
        </AppWrapper>
    );
};

export default UsersList;
