import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { notificationActions } from '../../core/resource/notification';
import { alertOptions } from '../../core/configs/Constants.config';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const CustToast = props => {
    const dispatch = useDispatch();
    const { isOpenSnackbar, duration, messageText, alertType } = useSelector(
        state => state.notification,
    );

    const handleCloseToast = () => {
        dispatch(notificationActions.closeSnackbar());
    };
    return (
        <div>
            <ToastContainer
                className="p-3 alter-toast"
                position={alertOptions.defaultAlertPosition}
                //style={{ zIndex: 1 }}
            >
                <Toast
                    onClose={() => handleCloseToast()}
                    show={isOpenSnackbar}
                    delay={alertOptions.defaultAlertDuration}
                    bg={alertType || 'danger'}
                    autohide>
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">{'Alert'}</strong>
                    </Toast.Header>
                    <Toast.Body className="alert-toast-msg">
                        {messageText || ''}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
};

export default CustToast;
