// import { all, call, put, select, take } from 'redux-saga/effects';
import { take, put, call, all, takeEvery } from 'redux-saga/effects';
import { genericActions as actions, genericService } from './index';

function* handleGetAnnouncementsRequest() {
    console.log('--handleGetAnnouncementsRequest--');
    const action = yield take(actions.getAnnouncementsRequest.getType());
    const dataParams = action.payload;

    let res;
    try {
        res = yield call(genericService.getAnnouncements, dataParams);
        if (res.isError) {
            yield put(actions.getAnnouncementsError(res));
        } else {
            yield put(actions.getAnnouncementsSuccess(res));
        }
    } catch (error) {
        yield put(actions.getAnnouncementsError());
    }
}

function* handleGetAnnouncementsRequestWatcher() {
    yield takeEvery(
        actions.getAnnouncementsRequest,
        handleGetAnnouncementsRequest,
    );
}

function* rootGenericSaga() {
    yield all([
        handleGetAnnouncementsRequest(),
        handleGetAnnouncementsRequestWatcher(),
    ]);
}

export default rootGenericSaga;
