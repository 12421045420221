import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from './root';
import { getStore } from './core/store';
import reportWebVitals from './reportWebVitals';
import { STORAGE_NAME, DB_NAME } from './core/configs/BrowserStorage.config';

const renderApp = storeToRestore => {
    const initUrl = new URL(window.location.href);
    const loginUserParam = initUrl.searchParams.get('loginuser');

    if (loginUserParam) {
        window.loginUserParam = loginUserParam;
    }
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        // <React.StrictMode>
        <Root store={getStore(storeToRestore)} />,
        // </React.StrictMode>  is We disable this our function runs only once
    );
};

if (window.clearStorage) {
    renderApp();
} else {
    const DBRequest = indexedDB.open(DB_NAME);
    DBRequest.onerror = function () {
        renderApp();
    };
    DBRequest.onsuccess = function (event) {
        try {
            const db = event.target.result;
            const transaction = db.transaction('keyvaluepairs');
            const objectStore = transaction.objectStore('keyvaluepairs');
            const DBQuery = objectStore.get(STORAGE_NAME);
            DBQuery.onerror = function () {
                renderApp();
            };
            DBQuery.onsuccess = function (ev) {
                renderApp(ev.target.result);
            };
        } catch (e) {
            renderApp();
        }
    };
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
