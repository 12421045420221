import React from 'react';
import Header from './sections/header';
import Footer from './sections/footer';
import FormPage from './layouts/form-page';

const FormIndex = () => {
    return (
        <>
            <Header />
            <main className="page-body-area py-4">
                <FormPage />
            </main>
            <Footer />
        </>
    );
};

export default FormIndex;
