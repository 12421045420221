import { createAction } from 'redux-act';

const handleClearAccountStore = createAction(
    'HANDLE_CLEAR_ACCOUNT_STORE',
    data => data,
);

const getUserBalanceRequest = createAction(
    'GET_USER_BALANCE_REQUEST',
    data => data,
);
const getUserBalanceSuccess = createAction(
    'GET_USER_BALANCE_SUCCESS',
    data => data,
);
const getUserBalanceError = createAction(
    'GET_USER_BALANCE_ERROR',
    data => data,
);

const getUserLadgerRequest = createAction(
    'GET_USER_LADGER_REQUEST',
    data => data,
);
const getUserLadgerSuccess = createAction(
    'GET_USER_LADGER_SUCCESS',
    data => data,
);
const getUserLadgerError = createAction('GET_USER_LADGER_ERROR', data => data);

const chipsDepositRequest = createAction('CHIPS_DEPOSIT_REQUEST', data => data);
const chipsDepositSuccess = createAction('CHIPS_DEPOSIT_SUCCESS', data => data);
const chipsDepositError = createAction('CHIPS_DEPOSIT_ERROR', data => data);

const chipsWithdrawRequest = createAction(
    'CHIPS_WITHDRAW_REQUEST',
    data => data,
);
const chipsWithdrawSuccess = createAction(
    'CHIPS_WITHDRAW_SUCCESS',
    data => data,
);
const chipsWithdrawError = createAction('CHIPS_WITHDRAW_ERROR', data => data);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    handleClearAccountStore,
    getUserBalanceRequest,
    getUserBalanceSuccess,
    getUserBalanceError,

    getUserLadgerRequest,
    getUserLadgerSuccess,
    getUserLadgerError,

    chipsDepositRequest,
    chipsDepositSuccess,
    chipsDepositError,

    chipsWithdrawRequest,
    chipsWithdrawSuccess,
    chipsWithdrawError,
};
