import { fork } from 'redux-saga/effects';
import { rootUserSaga } from './users';
import { rootAuthSaga } from './auth';
import { rootAccountSaga } from './account';
import { rootSportSaga } from './sports';
import { rootGenericSaga } from './generic';

function* rootSaga() {
    yield fork(rootUserSaga);
    yield fork(rootAuthSaga);
    yield fork(rootAccountSaga);
    yield fork(rootSportSaga);
    yield fork(rootGenericSaga);
}

export default rootSaga;
