import { createReducer } from 'redux-act';
import actions from './accountActions';

const initialState = {
    isGetUserBalancePending: false,
    getUserBalanceRes: null,
    getUserBalanceResError: null,

    isGetUserLadgerPending: false,
    getUserLadgerRes: null,
    getUserLadgerResError: null,

    isChipsDepositPending: false,
    chipsDepositRes: null,
    chipsDepositResError: null,

    isChipsWithdrawPending: false,
    chipsWithdrawRes: null,
    chipsWithdrawResError: null,
};

//
const handleGetUserBalanceRequest = state => {
    return {
        ...state,
        ...{
            isGetUserBalancePending: true,
            getUserBalanceRes: null,
            getUserBalanceResError: null,
        },
    };
};
const handleGetUserBalanceSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isGetUserBalancePending: false,
            getUserBalanceRes: payload,
            getUserBalanceResError: null,
        },
    };
};
const handleGetUserBalanceError = (state, payload) => ({
    ...state,
    ...{
        isGetUserBalancePending: false,
        getUserBalanceRes: null,
        getUserBalanceResError: payload,
    },
});

//
const handleGetUserLadgerRequest = state => {
    return {
        ...state,
        ...{
            isGetUserLadgerPending: true,
            getUserLadgerRes: null,
            getUserLadgerResError: null,
        },
    };
};
const handleGetUserLadgerSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isGetUserLadgerPending: false,
            getUserLadgerRes: payload,
            getUserLadgerResError: null,
        },
    };
};
const handleGetUserLadgerError = (state, payload) => ({
    ...state,
    ...{
        isGetUserLadgerPending: false,
        getUserLadgerRes: null,
        getUserLadgerResError: payload,
    },
});

//
const handleChipsDepositRequest = state => {
    return {
        ...state,
        ...{
            isChipsDepositPending: true,
            chipsDepositRes: null,
            chipsDepositResError: null,
        },
    };
};
const handleChipsDepositSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isChipsDepositPending: false,
            chipsDepositRes: payload,
            chipsDepositResError: null,
        },
    };
};
const handleChipsDepositError = (state, payload) => ({
    ...state,
    ...{
        isChipsDepositPending: false,
        chipsDepositRes: null,
        chipsDepositResError: payload,
    },
});

//
const handleChipsWithdrawRequest = state => {
    return {
        ...state,
        ...{
            isChipsWithdrawPending: true,
            chipsWithdrawRes: null,
            chipsWithdrawResError: null,
        },
    };
};
const handleChipsWithdrawSuccess = (state, payload) => {
    return {
        ...state,
        ...{
            isChipsWithdrawPending: false,
            chipsWithdrawRes: payload,
            chipsWithdrawResError: null,
        },
    };
};
const handleChipsWithdrawError = (state, payload) => ({
    ...state,
    ...{
        isChipsWithdrawPending: false,
        chipsWithdrawRes: null,
        chipsWithdrawResError: payload,
    },
});

//
const clearAccountStore = (state, payload) => {
    switch (payload) {
        case 'BALANCE_RES':
            return {
                ...state,
                ...{
                    isGetUserBalancePending: false,
                    getUserBalanceRes: null,
                    getUserBalanceResError: null,
                },
            };
        case 'LADGER_RES':
            return {
                ...state,
                ...{
                    isGetUserLadgerPending: false,
                    getUserLADGERRes: null,
                    getUserLADGERError: null,
                },
            };
        case 'CHIPS_DEPOSIT_RES':
            return {
                ...state,
                ...{
                    isChipsDepositPending: false,
                    chipsDepositRes: null,
                    chipsDepositResError: null,
                },
            };
        case 'CHIPS_WITHDRAW_RES':
            return {
                ...state,
                ...{
                    isChipsWithdrawPending: false,
                    chipsWithdrawRes: null,
                    chipsWithdrawResError: null,
                },
            };
        default:
            return state;
    }
};
export default createReducer(
    {
        [actions.getUserBalanceRequest]: handleGetUserBalanceRequest,
        [actions.getUserBalanceSuccess]: handleGetUserBalanceSuccess,
        [actions.getUserBalanceError]: handleGetUserBalanceError,

        [actions.getUserLadgerRequest]: handleGetUserLadgerRequest,
        [actions.getUserLadgerSuccess]: handleGetUserLadgerSuccess,
        [actions.getUserLadgerError]: handleGetUserLadgerError,

        [actions.chipsDepositRequest]: handleChipsDepositRequest,
        [actions.chipsDepositSuccess]: handleChipsDepositSuccess,
        [actions.chipsDepositError]: handleChipsDepositError,

        [actions.chipsWithdrawRequest]: handleChipsWithdrawRequest,
        [actions.chipsWithdrawSuccess]: handleChipsWithdrawSuccess,
        [actions.chipsWithdrawError]: handleChipsWithdrawError,

        [actions.handleClearAccountStore]: clearAccountStore,
    },
    initialState,
);
