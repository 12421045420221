import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { genericActions } from '../../core/resource/generic';
import { AppWrapper } from '../layouts';
import { AddUserModal } from '../modals';
import { convertToDate } from '../../utils/AppHelper';
import { defaultPerPageRecords } from '../../core/configs/Constants.config';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader,
} from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const AccountInfo = () => {
    const dispatch = useDispatch();
    const routeParams = useParams();

    const {
        isGetAnnouncementsPending,
        getAnnouncementsRes,
        getAnnouncementsResError,
    } = useSelector(state => state.generic);

    const [announcementList, setAnnouncementList] = useState([]);
    const [totalAnnouncements, setTotalAnnouncements] = useState(0);
    const [page, setPage] = useState(1);
    const [perPageRecords, setPerPageRecords] = useState(defaultPerPageRecords);

    useEffect(() => {
        getAnnouncements();
    }, []);

    useEffect(() => {
        if (!isGetAnnouncementsPending) {
            if (getAnnouncementsResError) {
                return;
            }
            if (
                getAnnouncementsRes?.data?.data &&
                getAnnouncementsRes?.data?.success
            ) {
                setAnnouncementList(getAnnouncementsRes.data.data || []);
            }
        }
    }, [
        isGetAnnouncementsPending,
        getAnnouncementsRes,
        getAnnouncementsResError,
    ]);

    const getAnnouncements = () => {
        dispatch(genericActions.getAnnouncementsRequest());
    };

    const getMaxPage = () => {
        const mNum = Math.ceil(totalAnnouncements / perPageRecords);
        return mNum;
    };

    const handleOnPageChange = pNum => {
        console.log({ pNum });
        setPage(pNum);
    };

    const handleOnPerPageChange = value => {
        setPerPageRecords(value);
    };

    const headers = [
        {
            isFilterable: false,
            isSortable: false,
            prop: 'My Balance',
            title: 'My Balance',
        },
        {
            isFilterable: false,
            isSortable: false,
            prop: 'Downline Balance',
            title: 'Downline Balance',
        },
    ];

    return (
        <AppWrapper>
            <div className="container py-5">
                <h3 className="mb-0 mt-1 me-4">Account Info</h3>
                <DatatableWrapper
                    className="table"
                    body={announcementList}
                    checkboxProps={{
                        onCheckboxChange: function noRefCheck() {},
                    }}
                    headers={headers}
                    sortProps={{
                        sortValueObj: {
                            date: function noRefCheck() {},
                        },
                    }}>
                    <Row className="mb-4">
                        <Col
                            className="d-flex flex-col justify-content-end align-items-end"
                            lg={4}
                            xs={12}>
                            <Filter />
                        </Col>
                    </Row>
                    <Table responsive bordered hover striped>
                        <TableHeader />
                        <TableBody>
                            {announcementList.map((row, rowIdx) => {
                                return (
                                    <tr key={rowIdx}>
                                        <td>49000</td>
                                        <td>1000</td>
                                    </tr>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <div className="table-pagination">
                        <Row className="mb-4">
                            <Col
                                className="d-flex flex-col align-items-center mb-2 mb-sm-0"
                                lg={3}
                                sm={3}
                                xs={12}>
                                <PaginationOptions
                                    alwaysShowPagination
                                    controlledProps={{
                                        rowsPerPage: perPageRecords,
                                        /** Number of rows shown per page options. */
                                        rowsPerPageOptions: [5, 10, 15, 20],
                                        // /**
                                        //  * The filtered data length. When not using filter control,
                                        //  * then this should equal to the table body's length.
                                        //  */
                                        filteredDataLength: perPageRecords,
                                        /** The function fired when any of the pagination option is changed. */
                                        onRowsPerPageChange: value =>
                                            handleOnPerPageChange(value),
                                    }}
                                />
                            </Col>
                            <Col
                                className="d-flex flex-col justify-content-end align-items-end"
                                lg={9}
                                sm={9}
                                xs={12}>
                                <Pagination
                                    alwaysShowPagination
                                    paginationRange={2}
                                    controlledProps={{
                                        currentPage: page,
                                        maxPage: getMaxPage(),
                                        onPaginationChange: pNum =>
                                            handleOnPageChange(pNum),
                                    }}
                                />
                            </Col>
                            <Col className="mt-2" xs={12}>
                                {/* <BulkCheckboxControl /> */}
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col xs={12}>{/* <StrayResetSelectionButton /> */}</Col>
                    </Row>
                </DatatableWrapper>
            </div>
        </AppWrapper>
    );
};

export default AccountInfo;
