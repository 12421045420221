import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import mainRouter from './routers/mainRouter';

const Root = props => {
    const { store } = props;
    return (
        <Provider store={store}>
            <BrowserRouter>
                {/* <PersistGate loading={null} persistor={persistor}> */}
                {mainRouter(store.dispatch)}
                {/* </PersistGate> */}
            </BrowserRouter>
        </Provider>
    );
};

Root.defaultProps = {
    store: {},
};

Root.propTypes = {
    store: PropTypes.shape().isRequired,
};
export default Root;
